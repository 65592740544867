/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { HOME_PATH } from '../../configs'
import Headline from '../../uiKit/texts/Headline'
import Submenu from '../../uiKit/submenu/Submenu'
import { settingsRoutes } from '../../routes/settingsRoutes'
import { getBot } from '../home/api/bots'
import Loader from '../../uiKit/loaders/loader'

const styles = theme => ({})

class Settings extends React.Component {
  componentDidMount() {
    getBot(this.props.match.params.botId)
  }

  render() {
    const pathLink = `${HOME_PATH}/bot/${this.props.match.params.botId}/settings`
    const pathRoute = `${HOME_PATH}/bot/:botId/settings/`
    return (
      <div>
        {this.props.activeBot ? (
          <div>
            <Headline title="Settings" />
            <Submenu
              pathLink={pathLink}
              pathRoute={pathRoute}
              routes={settingsRoutes}
            />
          </div>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Settings)),
)
