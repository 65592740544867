import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import Label from '../../../../uiKit/texts/Label'
import { PhotoIcon } from '../../../../uiKit/icons/Icons'
import { PERMISSION_RED } from '../../../../constants/colors'
import { handleDrop } from '../../api/widget'
import { ONE_MB } from '../../../../constants/dataSize'

import classes from './styles.module.scss'

interface Props {
  value: string
  onChange: (a: string) => void
}

const LogoDropzone: React.FC<Props> = ({ value, onChange }) => {
  const [errors, setErrors] = useState<string[]>([])

  const onDrop = (files: any[]) => {
    setErrors([])
    if (files[0].size > ONE_MB) {
      const errors = []
      errors.push('Maximum file size must be less than or equal to 1 Mb.')
      setErrors(errors)
    } else {
      const file = new FormData()
      file.append('file', files[0])
      handleDrop(file, onChange)
    }
  }

  return (
    <div className={classes.wrap}>
      <Label>Choose widget logo</Label>
      <Dropzone
        className={classes.container}
        onDrop={onDrop}
        style={{ borderColor: !!errors.length && PERMISSION_RED }}>
        {value ? (
          <img src={value} alt="file" className={classes.image} />
        ) : (
          <div className={classes.uploadContainer}>
            <div className={classes.iconWrap}>
              <PhotoIcon height="15px" width="19px" />
            </div>
            <p className={classes.label}>
              Drag and drop or <span>Upload</span>
            </p>
            <p className={classes.description}>1 MB max .jpeg or .png</p>
          </div>
        )}
      </Dropzone>
      {!!errors.length && <p className={classes.errorMessage}>{errors[0]}</p>}
    </div>
  )
}

export default LogoDropzone
