import React, { ReactEventHandler, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import ModalTemplate from '../../../../uiKit/ModalTemplate'
import Input from '../../../../uiKit/inputs/Input'
import RadioButtonGroup from '../../../../uiKit/RadioButtonGroup'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { updateWidget } from '../../api/widget'
import { setErrors } from '../../../../helpers/setErrors/setErrors'

import classes from './styles.module.scss'
import {OptionItemType} from '../../../../models/MessageTypes'
import {GreetingType, WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'

interface Props {
  match: any
  open: boolean
  selectedLanguage: OptionItemType
  widgetSettings: WidgetSettingsType
  selectedGreeting: GreetingType
  onClose: ReactEventHandler
}

const EditDefaultModal: React.FC<Props> = ({
  open,
  selectedGreeting,
  widgetSettings,
  selectedLanguage,
  match,
  onClose,
}) => {
  const [greetingText, setGreetingText] = useState('')
  const [delaySeconds, setDelaySeconds] = useState<string | number>('')
  const [errorsGreetingText, setErrorsGreetingText] = useState<string[]>([])
  const [errorsDelaySeconds, setErrorsDelaySeconds] = useState<string[]>([])

  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])
  const greetingCopy = useMemo(() => deepCopyFunction(selectedGreeting), [selectedGreeting])

  useEffect(() => {
    setGreetingText(selectedGreeting?.greetingText)
    setErrorsGreetingText([])
  }, [selectedGreeting?.greetingText])

  useEffect(() => {
    setDelaySeconds(selectedGreeting?.delaySeconds)
    setErrorsDelaySeconds([])
  }, [selectedGreeting?.delaySeconds])

  const validateGreetingText = (value: string) => {
    const errors = setErrors(value)
    setErrorsGreetingText(errors)

    return !errors.length
  }

  const validateDelayTime = (value: string | number) => {
    const errors = setErrors(value)
    setErrorsDelaySeconds(errors)

    return !errors.length
  }

  const handleSubmit = (event: any) => {
    const isValidGreetingText = validateGreetingText(greetingText)
    const isValidDelayTime = validateDelayTime(delaySeconds)

    if (isValidGreetingText && isValidDelayTime) {
      greetingCopy.delaySeconds = Number(delaySeconds)
      greetingCopy.greetingText = greetingText

      widgetCopy.languageConfigs[selectedLanguage.value].greetings[greetingCopy.index] = greetingCopy
      updateWidget(match.params.botId, widgetCopy)
      onClose(event)
    }
  }

  return (
    <ModalTemplate open={open} title="Edit Default greeting" onClose={onClose} onSave={handleSubmit}>
      <div className={classes.body}>
        <Input
          title="Greeting text"
          placeholder="Enter greeting text"
          value={greetingText || ''}
          error={!!errorsGreetingText.length}
          errorMessage={errorsGreetingText[0]}
          onChange={(event: any) => setGreetingText(event.target.value)}
          onBlur={() => validateGreetingText(greetingText)}
        />
        <RadioButtonGroup
          title="Delay time"
          value={delaySeconds}
          onChange={setDelaySeconds}
          onBlur={() => validateDelayTime(delaySeconds)}
          errors={errorsDelaySeconds}
        />
      </div>
    </ModalTemplate>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(EditDefaultModal))
