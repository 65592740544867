/**
 * Created by Admin on 13.02.2018.
 */
import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

const styles = {
  container: {
    marginTop: 10,
    position: 'relative',
    padding: 10,
    border: '1px solid #e5e5e5',
    borderRadius: 10,
    width: '100%',
  },
}

class Typing extends React.Component {
  handleChange = value => {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.time = value
    this.props.updateMessage(newMessage)
  }

  render() {
    return (
      <div className="slider" style={styles.container}>
        <p>Show typing for at least {this.props.message.time} seconds</p>
        <Slider
          min={0}
          max={20}
          value={this.props.message.time}
          onChangeStart={this.handleChangeStart}
          onChange={this.handleChange}
        />
        {this.props.onDelete && (
          <div className="dlt-msg" onClick={() => this.props.onDelete()}>
            <img src="/images/platform/delete.svg" alt="delete" />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  params: state.attributes,
})

export default connect(mapStateToProps)(Typing)
