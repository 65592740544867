import {
  saveNlpRequests,
  updateTrainedNlpRequest,
} from '../actions/nlpRequests'
import {
  addIntent,
  deleteOldIntent,
  saveIntents,
  updateExistedIntent,
} from '../actions/intents'
import {
  saveIntentsSize,
  addIntentsSize,
  deleteOldIntentsSize,
} from '../actions/intentsSize'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api/'
import { BASE_URL } from '../../../configs'

export function getIntents(botId, faq) {
  fetch(BASE_URL + '/intent/bot/' + botId + '/faq/' + faq, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveIntents(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export function getPagingIntents(botId, faq, page, query) {
  return fetch(
    BASE_URL +
      '/intent/bot/' +
      botId +
      '/faq/' +
      faq +
      '/' +
      page +
      '/search?query=' +
      query,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveIntentsSize(json.intentsSize)
          saveIntents(json.intentDTOList)
          return json.intentDTOList
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export function getAllPagingIntents(botId, query = '') {
  fetch(BASE_URL + '/intent/bot/' + botId + '?query=' + query, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveIntents(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export function getAllPagingIntentsForTriggers(botId, query) {
  fetch(
    BASE_URL + '/intent/bot/' + botId + '?includeFAQ=false&query=' + query,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveIntents(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export function getAllIntents(botId) {
  fetch(BASE_URL + '/intent/bot/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveIntents(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export function getTrainingData(botId, page, size, shortName) {
  fetch(
    BASE_URL +
      '/bot/' +
      botId +
      '/training' +
      '?page=' +
      page +
      '&size=' +
      size +
      '&shortName=' +
      shortName,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveNlpRequests(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting triggers please ping support!',
        5000,
      )
    })
}

export function getHistoryData(botId, page, size, shortName) {
  fetch(
    `${BASE_URL}/bot/${botId}/training/all?page=${page}&size=${size}&shortName=${shortName}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveNlpRequests(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting training data please ping support!',
      )
    })
}

export function getIntentUserSays(intentId) {
  return fetch(BASE_URL + '/intent/' + intentId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.status === 400) {
        response.text().then(text => alertSuccess(text))
        return
      }
      return logoutOnRequestOrResponseJson(response)
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting intent please ping support!',
      )
    })
}

export function createNewIntent(intent, intentsSize) {
  fetch(BASE_URL + '/intent', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(intent),
  })
    .then(response => {
      if (!response.ok) {
        throw response.json()
      }
      return logoutOnRequestOrResponseJson(response)
    })
    .then(json => {
      alertSuccess('Intent is created successfully.')
      addIntent(json)
      addIntentsSize(intentsSize)
    })
    .catch(function(error) {
      error.then(err => {
        alertError(`Create intent error: ${err.message}`, 10000)
      })
    })
}

export function trainIntent(request, botId) {
  fetch(BASE_URL + '/bot/' + botId + '/training/', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(request),
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          updateTrainedNlpRequest(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function updateIntent(intent, callback) {
  fetch(BASE_URL + '/intent', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(intent),
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          alertSuccess('Intent is updated successfully.')
          updateExistedIntent(json)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function deleteIntent(intentId, intentsSize) {
  fetch(BASE_URL + '/intent/' + intentId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          alertSuccess('Intent is deleted successfully.')
          deleteOldIntent(intentId)
          deleteOldIntentsSize(intentsSize)
        })
      }
      response.text().then(text => alertSuccess(text))
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}
