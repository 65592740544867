import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getClosedSupports } from '../../api/users'
import {
  saveClosedSupportUsers,
  saveMoreClosedSupportUsers,
} from '../../actions/users'
import UserDialogPreview from '../UserDialogPreview/UserDialogPreview'
import { isElementBottom } from '../../../../helpers/isElementBottom'

import { styles } from './styles'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'
import Search from '../Search/Search'
import useDebounce from '../../../../hooks/useDebounce'

const SolvedRequests = props => {
  const { classes, match, closedRequestsUsers } = props
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      getClosedSupports(match.params.botId, debouncedSearchTerm).then(users => {
        saveClosedSupportUsers(users)
      })
    } else {
      getClosedSupports(match.params.botId)
        .then(users => {
          saveClosedSupportUsers(users)
        })
        .then(() => setLoading(false))
    }
  }, [debouncedSearchTerm])

  const handleScroll = event => {
    if (isElementBottom(event.target)) {
      if (search) {
        getClosedSupports(
          match.params.botId,
          debouncedSearchTerm,
          closedRequestsUsers.length,
        ).then(users => {
          saveMoreClosedSupportUsers(users)
        })
      } else {
        getClosedSupports(
          match.params.botId,
          undefined,
          closedRequestsUsers.length,
        ).then(users => {
          saveMoreClosedSupportUsers(users)
        })
      }
    }
  }

  return (
    <>
      <div className={classes.searchContainer}>
        <Search search={search} handleSearch={setSearch} />
      </div>
      {loading ? (
        <div className={classes.loaderWrap}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <div className={classes.container} onScroll={handleScroll}>
          {closedRequestsUsers?.map(user => (
            <UserDialogPreview user={user} key={user.userId}/>
          ))}
        </div>
      )}
    </>
  )
}

SolvedRequests.propTypes = {
  classes: PropTypes.object,
  closedRequestsUsers: PropTypes.array,
}

const mapStateToProps = state => ({
  closedRequestsUsers: state.supportUsersNew.closedRequestsUsers,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(SolvedRequests)),
)
