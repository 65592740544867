import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import { withRouter } from 'react-router-dom'

import Popover from '../../../../uiKit/Popover'
import Table from '../../../../uiKit/table/Table'
import TableRow from '../../../../uiKit/table/TableRow'
import TableCell from '../../../../uiKit/table/TableCell'
import { ThreeIcon } from '../../../../uiKit/icons/ThreeIcon'
import { DeleteIcon } from '../../../../uiKit/icons/DeleteIcon'
import { EditIcon } from '../../../../uiKit/icons/EditIcon'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { updateWidget } from '../../api/widget'

import classes from './styles.module.scss'
import { GreetingType, WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'
import { OptionItemType } from '../../../../models/MessageTypes'

const titles = [
  { title: 'Greeting name', maxWidth: 270 },
  { title: 'Sent', maxWidth: 120 },
  { title: 'Opened', maxWidth: 120 },
  { title: 'Enable/disable', maxWidth: 120 },
  { title: '', maxWidth: 120 },
]

interface Props {
  greetings: GreetingType[]
  widgetSettings: WidgetSettingsType
  selectedLanguage: OptionItemType
  onToggleEditModal: (a: boolean, b: GreetingType) => void
  onToggleDeleteModal: (a: boolean, b: GreetingType) => void
  onToggleEditDefaultModal: (a: boolean, b: GreetingType) => void
  match: any
}

const GreetingsTable: React.FC<Props> = ({
  greetings,
  widgetSettings,
  selectedLanguage,
  onToggleEditModal,
  onToggleDeleteModal,
  onToggleEditDefaultModal,
  match,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openedPopoverId, setOpenedPopoverId] = useState<null | number>(null)
  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleSwitch = (index: number) => {
    widgetCopy.languageConfigs[selectedLanguage.value].greetings[index].enabled =
      !widgetCopy.languageConfigs[selectedLanguage.value].greetings[index].enabled
    updateWidget(match.params.botId, widgetCopy)
  }

  const handleOpenPopover = (event: any, index: number) => {
    setOpenedPopoverId(index)
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const handleEditDefaultGreeting = (greeting: GreetingType, index: number) => {
    greeting.index = index
    onToggleEditDefaultModal(true, greeting)
    handleClosePopover()
  }

  const handleEditGreeting = (greeting: GreetingType, index: number) => {
    greeting.index = index
    onToggleEditModal(true, greeting)
    handleClosePopover()
  }

  const handleDeleteGreeting = (greeting: GreetingType, index: number) => {
    greeting.index = index
    onToggleDeleteModal(true, greeting)
    handleClosePopover()
  }

  return (
    <Table titles={titles}>
      {greetings?.map((greeting: GreetingType, index: number) => (
        <TableRow key={greeting.hash} index={index}>
          <TableCell styles={{ maxWidth: 270 }}>{greeting.name}</TableCell>
          <TableCell styles={{ maxWidth: 120 }}>{!greeting.isDefault && greeting.sent}</TableCell>
          <TableCell styles={{ maxWidth: 120 }}>{!greeting.isDefault && greeting.opened}</TableCell>
          <TableCell styles={{ maxWidth: 120 }}>
            {!greeting.isDefault && (
              <Switch color="primary" checked={greeting.enabled} onChange={() => handleSwitch(index)} />
            )}
          </TableCell>
          <TableCell styles={{ maxWidth: 120, flexDirection: 'row-reverse' }}>
            {!greeting.isDefault ? (
              <div className={classes.button} onClick={event => handleOpenPopover(event, index)}>
                <ThreeIcon aria-describedby={id} variant="contained" color="primary" />
              </div>
            ) : (
              <div className={classes.button} onClick={() => handleEditDefaultGreeting(greeting, index)}>
                Edit
              </div>
            )}
          </TableCell>
          <Popover id={id} open={openedPopoverId === index} anchorEl={anchorEl} onClose={handleClosePopover}>
            <div>
              <div className={classes.editButton} onClick={() => handleEditGreeting(greeting, index)}>
                <EditIcon /> Edit
              </div>
              <div className={classes.deleteButton} onClick={() => handleDeleteGreeting(greeting, index)}>
                <DeleteIcon /> Delete
              </div>
            </div>
          </Popover>
        </TableRow>
      ))}
    </Table>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(GreetingsTable))
