export const styles = () => ({
  container: {
    borderRadius: 10,
    border: '1px solid #EBEBEF',
    width: '100%',
    marginBottom: 20,
  },
  header: {
    padding: '12px',
    color: 'rgba(58, 63, 98, 1)',
    borderBottom: '2px solid #EBEBEF',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteButton: {
    color: 'rgba(255, 98, 76, 1)',
    fontSize: 12,
    cursor: 'pointer',
  },
  body: {
    padding: 16,
  },
  label: {
    fontSize: 14,
    color: '#354052',
    margin: '24px 0px 8px',
    '& span': {
      color: 'red',
    },
  },
})
