import React from 'react'
import classes from './styles.module.scss'

interface Props {
  headers: Headers
}

const HeadersPreview:React.FC<Props> = ({ headers }) => {
  return (
    <div className={classes.headers}>
      {Object.entries(headers).map(([key, value], index) => (
        <div key={index} className={classes.header}>
          <span>{key}</span>
          <span>: </span>
          <span>{value[0]}</span>
        </div>
      ))}
    </div>
  )
}

export default HeadersPreview
