import React, { FC, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import Popover from '@material-ui/core/Popover'

import { ThreeIcon } from '../../../../uiKit/icons/TreeDots'
import { HOME_PATH } from '../../../../configs'

import classes from './styles.module.scss'

interface Props {
  botId: number
  userId: string
  messageId: string
}

const PopoverSupport: FC<Props> = ({ botId, userId, messageId }) => {
  const [target, setTarget] = useState(null)
  const [isOpenPopover, setOpenPopover] = useState(false)

  const redirectToChat = () => {
    const link = `${HOME_PATH}/bot/${botId}/support/${userId}#${messageId}`
    window.open(link, '_blank')
    setOpenPopover(false)
  }

  return (
    <Fragment>
      <div onClick={() => setOpenPopover(true)} style={{ cursor: 'pointer' }} ref={node => setTarget(node)}>
        <ThreeIcon />
      </div>
      <Popover
        classes={{ paper: classes.popover }}
        open={isOpenPopover}
        anchorEl={target}
        onClose={() => setOpenPopover(!isOpenPopover)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={classes.filterItem} onClick={redirectToChat}>
          Go to chat
        </div>
      </Popover>
    </Fragment>
  )
}

const mapStateToProps = (state: { activeBot: { id: any } }) => ({
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(PopoverSupport)
