import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import ConditionExpression from '../ConditionExpression/ConditionExpression'
import { usePrevious } from '../../../../hooks/usePrevious'
import { generateId } from '../../../../helpers/generateId'

const ConditionGroup = props => {
  const { classes, onDelete, condition, updateCondition, saveTime } =
    props
  const [touched, setTouched] = useState({ redirectTo: false })
  const prevSaveTime = usePrevious(saveTime)

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      const touched = {
        redirectTo: true,
      }

      setTouched(touched)
    }
  }, [saveTime])

  const handleUpdateRedirectTo = e => {
    const conditionCopy = deepCopyFunction(condition)
    conditionCopy.redirectTo = e.value

    updateCondition(conditionCopy)
  }

  const handleDeleteExpression = index => {
    const conditionCopy = deepCopyFunction(condition)
    conditionCopy.expressions.splice(index, 1)

    updateCondition(conditionCopy)
  }

  const handleUpdateExpression = (expression, index) => {
    const conditionCopy = deepCopyFunction(condition)
    conditionCopy.expressions[index] = expression

    updateCondition(conditionCopy)
  }

  const handleUpdateConjunction = e => {
    const conditionCopy = deepCopyFunction(condition)
    conditionCopy.conjunction = e.value

    updateCondition(conditionCopy)
  }

  const handleAddExpression = () => {
    const conditionCopy = deepCopyFunction(condition)
    conditionCopy.expressions.push({
      attribute: null,
      operator: 'IS',
      value: '',
      id: generateId(),
    })

    updateCondition(conditionCopy)
  }

  const updateTouched = (field, isTouched) => {
    const touchedCopy = deepCopyFunction(touched)
    touchedCopy[field] = isTouched

    setTouched(touchedCopy)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>Condition group</span>
        <span className={classes.deleteButton} onClick={onDelete}>
          Delete
        </span>
      </div>
      <div className={classes.body}>
        {condition?.expressions?.map((expression, index) => (
          <ConditionExpression
            key={expression.id}
            expression={expression}
            isLast={condition?.expressions?.length === index + 1}
            isOne={condition?.expressions?.length === 1}
            conjunction={condition?.conjunction}
            onUpdateConjunction={handleUpdateConjunction}
            onDelete={() => handleDeleteExpression(index)}
            onUpdate={expression => handleUpdateExpression(expression, index)}
            onAdd={handleAddExpression}
            saveTime={saveTime}
          />
        ))}
        <div className={classes.inputWrap}>
          <p className={classes.label}>
            Redirect to <span>*</span>
          </p>
          <AtomSelect
            value={condition?.redirectTo}
            onChange={handleUpdateRedirectTo}
            touched={touched.redirectTo}
            onFocus={() => updateTouched('redirectTo', true)}
          />
        </div>
      </div>
    </div>
  )
}
ConditionGroup.propTypes = {
  classes: PropTypes.object,
}

export default React.memo(withStyles(styles)(ConditionGroup))
