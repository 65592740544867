import React, { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { clearFunnelState } from '../../actions/funnel'
import LoaderScreen from '../../../../uiKit/loaders/loaderScreen'
import CompletionRateChart from '../CompletionRateChart'
import ChartContainer from '../../../../uiKit/ChartContainer'
import { getFlows } from '../../../flowBuilder/api/flow'
import { getFunnel } from '../../api/funnels'
import { getBot } from '../../../home/api/bots'
import Transcripts from '../Transcripts'
import HeadlineWrapWithDateControl from '../../../../uiKit/HeadlineWrapWithDateControl'

import classes from './styles.module.scss'
import {FunnelType} from '../../../../models/FunnelTypes'

interface Props {
  funnel: FunnelType
  match: any
}

const tooltipText = 'Step completion rate shows a percentage of users who entered the flow and received this step atom.'

const Funnel: FC<Props> = ({ match, funnel }) => {
  const [selectedStepNumber, setSelectedStep] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [loader, setLoader] = useState(true)

  const loadData = [
    {
      request: getFunnel,
      params: { funnelId: match.params.funnelId },
    },
  ]

  useEffect(() => {
    if (funnel !== null) {
      setLoader(false)
    }
  }, [funnel])

  useEffect(() => {
    getBot(match.params.botId)
    getFlows(match.params.botId)
    return clearFunnelState
  }, [])

  return (
    <div className={classes.container}>
      <div className={selectedStepNumber ? classes.chartContainerSelected : classes.chartContainer}>
        <HeadlineWrapWithDateControl
          title={funnel?.name}
          botId={match.params.botId}
          loadData={loadData}
          setFromDateCallback={setFromDate}
          setToDateCallback={setToDate}
        />
        {loader ? (
          <LoaderScreen />
        ) : (
          <ChartContainer title={'Completion rate'} tooltipText={tooltipText}>
            <CompletionRateChart onSelect={setSelectedStep} selectedStepId={selectedStepNumber} />
          </ChartContainer>
        )}
      </div>

      {selectedStepNumber && (
        <Transcripts
          selectedStepNumber={selectedStepNumber}
          onClose={() => setSelectedStep(false)}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: { funnel: FunnelType }) => ({
  funnel: state.funnel,
})

export default withRouter(connect(mapStateToProps)(Funnel))
