import React, { FC, useEffect, useMemo, useState } from 'react'
import { HorizontalBar } from 'react-chartjs-2'

import { initialColors, initialDataSet, chartOptions } from '../../constants/chartConfigs'
import { DANUBE_BLUE, DULL_LAVANDER_BLUE } from '../../../../constants/colors'

import classes from './styles.module.scss'

interface Props {
  data: any
  handleSelectedBar: (a: number | null) => void
  selectedBar: number | null
}

const Chart: FC<Props> = ({ data, handleSelectedBar, selectedBar }) => {
  const [dataSet, setDataSet] = useState(Object.assign({}, initialDataSet))
  const options = useMemo(() => Object.assign({}, chartOptions), [])

  useEffect(() => {
    const dataSetCopy = Object.assign({}, dataSet)
    dataSetCopy.datasets[0].data = Object.values(data).reverse()

    setDataSet(dataSetCopy)
  }, [data])

  options.onClick = function(evt: any, element: any[]) {
    if (element.length > 0) {
      const copyDataSet = Object.assign({}, dataSet)
      const formedIndex = Math.abs(5 - element[0]._index)

      copyDataSet.datasets[0].backgroundColor = [...initialColors]
      copyDataSet.datasets[0].hoverBackgroundColor = [...initialColors]

      if (selectedBar === formedIndex) {
        copyDataSet.datasets[0].backgroundColor[element[0]._index] = DULL_LAVANDER_BLUE
        copyDataSet.datasets[0].hoverBackgroundColor[element[0]._index] = DULL_LAVANDER_BLUE
        handleSelectedBar(null)
      } else {
        copyDataSet.datasets[0].backgroundColor[element[0]._index] = DANUBE_BLUE
        copyDataSet.datasets[0].hoverBackgroundColor[element[0]._index] = DANUBE_BLUE
        handleSelectedBar(formedIndex)
      }

      setDataSet(copyDataSet)
    }
  }

  return (
    <div className={classes.container}>
      <HorizontalBar data={dataSet} options={options} />
    </div>
  )
}

export default Chart
