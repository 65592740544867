export function getSelectionHtml() {
  let html = ''
  if (window.getSelection) {
    const sel = window.getSelection && window.getSelection()
    if (sel.rangeCount) {
      const container = document.createElement('div')
      for (let i = 0; i < sel.rangeCount; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents())
      }
      html = container.innerHTML
    }
  } else {
    if (typeof document.getSelection() !== 'undefined') {
      if (document.getSelection().type == 'Range') {
        // @ts-ignore
        html = document.selection.createRange().htmlText
      }
    }
  }
  return html
}
