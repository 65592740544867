import { deepCopyFunction } from './deepCopyFunction'
import { API_CALL_TYPE } from '../tabs/flowBuilder/constants/messageTypes'

export const formatAtom = atom => {
  const copyAtom = deepCopyFunction(atom)

  if (copyAtom?.messages) {
    copyAtom.messages = copyAtom.messages.map(message => {
      if (message.type === API_CALL_TYPE) {
        const apiCall = deepCopyFunction(message.apiCall)

        message.apiCall.headers = Object.entries(apiCall.headers || {}).map(([key, value]) => ({ key, value }))

        message.apiCall.attributes = Object.entries(apiCall.attributes || {}).map(([key, value]) => ({ key, value }))
      }
      return message
    })
  }

  return copyAtom
}
