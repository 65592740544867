export const getPopupPosition = (element: any, container: any) => {
  const absoluteTop = element?.top + element?.height
  const absoluteRight = element?.left + element?.width
  const isEnoughSpaceHeight = absoluteTop + container.height > window.innerHeight
  const isEnoughSpaceWidth = absoluteRight + container.width < window.innerWidth
  const top = absoluteTop - (isEnoughSpaceHeight && container.height) + 'px'
  const left = absoluteRight - (!isEnoughSpaceWidth && container.width) + 'px'

  return { top, left }
}
