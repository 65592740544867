import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'

const ActionModalItem = props => {
  const { classes, icon, name, description, onClick } = props

  return (
    <div className={classes.item} onClick={onClick}>
      {icon}
      <div className={classes.text}>
        <p className={classes.messageName}>{name}</p>
        <p className={classes.messageDescription}>{description}</p>
      </div>
    </div>
  )
}
ActionModalItem.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ActionModalItem)
