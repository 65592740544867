import styled from 'styled-components'
import { CORNFLOWER_BLUE, RIBBON_BLUE, SELAGO_BLUE, SHARK_BLACK, WHITE } from 'constants/colors'

const TextMessage = styled.span<{ isReceived?: boolean; isRtl?: boolean; highlight?: boolean }>`
  max-width: 78%;
  padding: 8px 16px;
  font-size: 14px;
  display: inline-block;
  border-radius: ${({ isReceived }) => (isReceived ? '10px 10px 10px 2px' : '10px 10px 2px 10px')};
  color: ${({ isReceived }) => (isReceived ? SHARK_BLACK : WHITE)};
  direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  border: ${({ highlight }) => highlight && `1px solid ${RIBBON_BLUE}`};
  background: ${({ isReceived, highlight }) => {
    if (highlight) {
      return CORNFLOWER_BLUE
    } else if (isReceived) {
      return SELAGO_BLUE
    }
    return RIBBON_BLUE
  }};
  span {
    cursor: pointer;
    text-decoration: underline;
    &[style] {
      color: ${WHITE} !important;
    }
  }
`
export { TextMessage }
