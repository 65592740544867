/* eslint-disable */
import React from 'react'

export const SearchIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16663 2.33332C4.94496 2.33332 2.33329 4.945 2.33329 8.16666C2.33329 11.3883 4.94496 14 8.16663 14C11.3883 14 14 11.3883 14 8.16666C14 4.945 11.3883 2.33332 8.16663 2.33332ZM0.666626 8.16666C0.666626 4.02452 4.02449 0.666656 8.16663 0.666656C12.3088 0.666656 15.6666 4.02452 15.6666 8.16666C15.6666 12.3088 12.3088 15.6667 8.16663 15.6667C4.02449 15.6667 0.666626 12.3088 0.666626 8.16666Z"
        fill="#354052"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2857 12.2857C12.6111 11.9603 13.1388 11.9603 13.4642 12.2857L17.0892 15.9107C17.4147 16.2362 17.4147 16.7638 17.0892 17.0892C16.7638 17.4147 16.2361 17.4147 15.9107 17.0892L12.2857 13.4642C11.9603 13.1388 11.9603 12.6112 12.2857 12.2857Z"
        fill="#354052"
        fillOpacity="0.7"
      />
    </svg>
  )
}
