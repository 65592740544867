/* eslint-disable */
import React from 'react'

export const SetAttributeIcon = () => {

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.09902 7.09609C6.29902 8.39609 6.49902 8.69609 6.49902 9.99609C6.49902 10.7961 4.99902 11.4961 4.99902 11.4961V12.4961C4.99902 12.4961 6.49902 13.1961 6.49902 13.9961C6.49902 15.2961 6.29902 15.5961 6.09902 16.8961C5.79902 18.9961 6.89902 19.9961 7.89902 19.9961C8.89902 19.9961 9.99902 19.9961 9.99902 19.9961V17.9961C9.99902 17.9961 8.19902 18.1961 8.19902 16.9961C8.19902 16.0961 8.39902 16.0961 8.59902 14.0961C8.69902 13.1961 8.09902 12.4961 7.49902 11.9961C8.09902 11.4961 8.69902 10.8961 8.59902 9.99609C8.29902 7.99609 8.19902 7.99609 8.19902 7.09609C8.19902 5.89609 9.99902 5.99609 9.99902 5.99609V3.99609C9.99902 3.99609 8.99902 3.99609 7.89902 3.99609C6.79902 3.99609 5.79902 4.99609 6.09902 7.09609Z"
        fill="#5A98F7"
      />
      <path
        d="M17.899 7.09609C17.699 8.39609 17.499 8.69609 17.499 9.99609C17.499 10.7961 18.999 11.4961 18.999 11.4961V12.4961C18.999 12.4961 17.499 13.1961 17.499 13.9961C17.499 15.2961 17.699 15.5961 17.899 16.8961C18.199 18.9961 17.099 19.9961 16.099 19.9961C15.099 19.9961 13.999 19.9961 13.999 19.9961V17.9961C13.999 17.9961 15.799 18.1961 15.799 16.9961C15.799 16.0961 15.599 16.0961 15.399 14.0961C15.299 13.1961 15.899 12.4961 16.499 11.9961C15.899 11.4961 15.299 10.8961 15.399 9.99609C15.599 7.99609 15.799 7.99609 15.799 7.09609C15.799 5.89609 13.999 5.99609 13.999 5.99609V3.99609C13.999 3.99609 14.999 3.99609 16.099 3.99609C17.199 3.99609 18.199 4.99609 17.899 7.09609Z"
        fill="#5A98F7"
      />
    </svg>
  )
}
