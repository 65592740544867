import React from 'react'
import ReactTooltip from 'react-tooltip'
import styles from './styles.module.scss'

interface Props {
  model: any
  color: any
  day: any
  getDayData: any
}

const DayModel: React.FC<Props> = ({ model, color, day, getDayData }) => {
  const { opacity: colorOpacity, amount } = getDayData(day.dayToAmount, day.dayOfWeek, model.time)
  const backgroundColor = color && `rgba(${color.r}, ${color.g}, ${color.b}, ${colorOpacity / 100})`

  return (
    <div
      key={model.time}
      data-tip
      data-for={`${day.dayOfWeek}-tooltip-${model.time}`}
      style={{ backgroundColor }}
      className={styles.gridItem}>
      <ReactTooltip
        className={styles.tooltip}
        place="top"
        type="light"
        effect="solid"
        id={`${day.dayOfWeek}-tooltip-${model.time}`}>
        <span>{amount}</span>
      </ReactTooltip>
    </div>
  )
}

export default DayModel
