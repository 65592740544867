import { RefObject } from 'react'

export const selectionChangeListener = (inputRef: RefObject<HTMLElement>, setSelectionText: (a: string) => void) => {
  setSelectionText(document.getSelection().toString())

  if (document.getSelection()?.rangeCount > 0) {
    const parent = window.getSelection().getRangeAt(0).commonAncestorContainer.parentElement
    if (parent.tagName.toLowerCase() === 'span' && parent.parentElement === inputRef.current) {
      const range = document.createRange()
      const sel = window.getSelection()
      range.setStartBefore(parent)
      range.setEndAfter(parent)
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}
