/* eslint-disable */
import React from 'react'

export const AttributeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.06536 4.73073C4.1987 5.5974 4.33203 5.7974 4.33203 6.66406C4.33203 7.1974 3.33203 7.66406 3.33203 7.66406V8.33073C3.33203 8.33073 4.33203 8.7974 4.33203 9.33073C4.33203 10.1974 4.1987 10.3974 4.06536 11.2641C3.86536 12.6641 4.5987 13.3307 5.26536 13.3307C5.93203 13.3307 6.66536 13.3307 6.66536 13.3307V11.9974C6.66536 11.9974 5.46536 12.1307 5.46536 11.3307C5.46536 10.7307 5.5987 10.7307 5.73203 9.3974C5.7987 8.7974 5.3987 8.33073 4.9987 7.9974C5.3987 7.66406 5.7987 7.26406 5.73203 6.66406C5.53203 5.33073 5.46536 5.33073 5.46536 4.73073C5.46536 3.93073 6.66536 3.9974 6.66536 3.9974V2.66406C6.66536 2.66406 5.9987 2.66406 5.26536 2.66406C4.53203 2.66406 3.86536 3.33073 4.06536 4.73073Z"
        fill="white"
      />
      <path
        d="M11.932 4.73073C11.7987 5.5974 11.6654 5.7974 11.6654 6.66406C11.6654 7.1974 12.6654 7.66406 12.6654 7.66406V8.33073C12.6654 8.33073 11.6654 8.7974 11.6654 9.33073C11.6654 10.1974 11.7987 10.3974 11.932 11.2641C12.132 12.6641 11.3987 13.3307 10.732 13.3307C10.0654 13.3307 9.33203 13.3307 9.33203 13.3307V11.9974C9.33203 11.9974 10.532 12.1307 10.532 11.3307C10.532 10.7307 10.3987 10.7307 10.2654 9.3974C10.1987 8.7974 10.5987 8.33073 10.9987 7.9974C10.5987 7.66406 10.1987 7.26406 10.2654 6.66406C10.3987 5.33073 10.532 5.33073 10.532 4.73073C10.532 3.93073 9.33203 3.9974 9.33203 3.9974V2.66406C9.33203 2.66406 9.9987 2.66406 10.732 2.66406C11.4654 2.66406 12.132 3.33073 11.932 4.73073Z"
        fill="white"
      />
    </svg>
  )
}
