import styled from 'styled-components'
import { OXFORD_BLUE, WHITE } from 'constants/colors'

const Title = styled.p`
  font-size: 16px;
  color: ${OXFORD_BLUE};
  font-weight: 600;
  text-align: center;
`

const ButtonsWrap = styled.div`
  display: flex;
  gap: 8px;
`

const Container = styled.div`
  position: absolute;
  background-color: ${WHITE};
  padding: 40px 90px;
  outline: none;
  width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export { Title, ButtonsWrap, Container }
