export const styles = () => ({
  container: {
    borderRadius: 10,
    border: '1px solid rgba(53, 64, 82, 0.1)',
    position: 'relative',
    margin: '10px 0px',
    width: '100%',
    '&:hover': {
      '& i': {
        display: 'block',
      },
    },
  },
  header: {
    padding: 18,
    fontSize: 14,
    borderBottom: '1px solid #EBEBEF',
    display: 'flex',
  },
  body: {
    padding: '24px 0px 24px 24px',
  },
  footer: {
    borderTop: '1px solid #EBEBEF',
    padding: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: '#3A3F62',
    margin: 0,
    fontWeight: 700,
  },
  infoIcon: {
    cursor: 'pointer',
    marginLeft: 6,
  },
  labels: {
    display: 'flex',
    gap: '10px',
    paddingRight: '32px',
    '& p': {
      width: '50%',
      fontSize: 14,
      fontWeight: '700',
      color: '#354052',
    },
  },
  iconWrap: {
    cursor: 'pointer',
    display: 'none',
    alignItems: 'center',
    marginLeft: 4,
    position: 'absolute',
    right: '4px',
    top: '6px',
  },
  attributeWrap: {
    display: 'flex',
    flexFlow: 'row',
    marginBottom: 9,
    gap: '10px',
    position: 'relative',
    paddingRight: '32px',
    '&:hover > u': {
      display: 'flex',
    },
  },
  warning: {
    position: 'absolute',
    color: '#FF6666',
    fontSize: 12,
  },
  input: {
    border: '1px solid rgba(53, 64, 82, 0.1)',
    outline: 'none',
    height: 38,
    borderRadius: 4,
    width: '214px',
    padding: '8px 12px',
    color: 'rgba(53, 64, 82, 0.7)',
  },
  deleteTemplate: {
    right: '28px',
    top: '-16px',
    position: 'absolute',
    display: 'none',
    transition: 'all 0.5s ease',
  },
  deleteIcon: {
    background: 'white',
    border: '1px solid #EBEBEF',
    boxShadow: '0px 3px 6px #EBEBEF',
    width: 40,
    height: 40,
    position: 'absolute',
    right: -40,
    display: 'block',
  },
})
