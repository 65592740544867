import { ARROW_UP_KEY, ARROW_DOWN_KEY } from '../../../../constants/keyCodes'
import { RefObject } from 'react'

export const calculateSelectedChildNodeIndex = (
  direction: ARROW_UP_KEY | ARROW_DOWN_KEY,
  attributeList: RefObject<HTMLElement>,
  selectedAttribute: number,
) => {
  const childNodes = attributeList?.current?.childNodes
  const directionStep = direction === ARROW_UP_KEY ? -1 : 1
  const childNodesLength = childNodes?.length - 1
  let selectedChildNodeIndex

  if (childNodes[selectedAttribute + directionStep] !== undefined) {
    selectedChildNodeIndex = selectedAttribute + directionStep
  } else {
    selectedChildNodeIndex = direction === ARROW_UP_KEY ? childNodesLength : 1
  }

  if (childNodes[selectedChildNodeIndex]?.nodeName !== 'DIV') {
    selectedChildNodeIndex =
      selectedChildNodeIndex + directionStep < 1 ? childNodesLength : selectedChildNodeIndex + directionStep
  }

  return selectedChildNodeIndex
}
