import { BASE_URL } from '../../../configs'
import api from '../../../services/api'
import { addFunnels, deleteFunnelAction, setFunnels, updateFunnelAction } from '../actions/funnels'
import { alertError, alertSuccess } from '../../../api'
import { setFunnel } from '../actions/funnel'

async function getFunnels({ botId, from, to }) {
  try {
    const data = await api.get(`${BASE_URL}/bot/${botId}/analytics/funnels?from=${from}&to=${to}`)

    if (data) {
      setFunnels(data)
    }
  } catch (error) {
    alertError(error.message)
  }
}

async function addFunnel({ botId, funnel, from, to }) {
  try {
    const newFunnel = await api.post(`${BASE_URL}/bot/${botId}/analytics/funnels`, funnel)
    const data = await api.get(`${BASE_URL}/bot/${botId}/analytics/funnels/${newFunnel.id}?from=${from}&to=${to}`)

    if (data) {
      addFunnels(data)
      alertSuccess('Funnel is created successfully')
    }
  } catch (error) {
    alertError(error.message)
  }
}

async function updateFunnel({ botId, funnel, from, to }) {
  try {
    const newFunnel = await api.patch(`${BASE_URL}/bot/${botId}/analytics/funnels`, funnel)
    const data = await api.get(`${BASE_URL}/bot/${botId}/analytics/funnels/${newFunnel.id}?from=${from}&to=${to}`)

    if (data) {
      updateFunnelAction(data)
      alertSuccess('Funnel is updated successfully')
    }
  } catch (error) {
    alertError(error.message)
  }
}

async function deleteFunnel({ botId, funnelId }) {
  try {
    await api.delete(`${BASE_URL}/bot/${botId}/analytics/funnels/${funnelId}`)
    deleteFunnelAction(funnelId)
    alertSuccess('Funnel is deleted successfully')
  } catch (error) {
    alertError(error.message)
  }
}

async function getFunnel({ botId, from, to, funnelId }) {
  try {
    const data = await api.get(`${BASE_URL}/bot/${botId}/analytics/funnels/${funnelId}?from=${from}&to=${to}`)
    setFunnel(data)
    return data
  } catch (error) {
    alertError(error.message)
  }
}

async function getConversations({ botId, funnelId, stepNumber, from, to, page, filter }) {
  try {
    const params = new URLSearchParams({
      from,
      to,
      stepNumber,
      filter,
      size: '1',
      page: page || '0',
      sort: 'date',
    })
    return await api.get(`${BASE_URL}/bot/${botId}/analytics/funnels/${funnelId}/conversations?${params}`)
  } catch (error) {
    alertError(error.message)
  }
}

async function getConversation({ botId, conversationId, page }) {
  try {
    const params = new URLSearchParams({
      page,
      size: '20',
      sort: 'id,desc',
    })
    return await api.get(`${BASE_URL}/bot/${botId}/conversations/${conversationId}?${params}`)
  } catch (error) {
    alertError(error.message)
  }
}

export { getFunnels, addFunnel, updateFunnel, deleteFunnel, getFunnel, getConversations, getConversation }
