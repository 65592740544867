import { getAttributeColor } from 'helpers/getAttributeColor'
import { createAttribute } from 'tabs/settings/api/attributes'
import { AttributeItemType } from 'models/AttributeTypes'

export const backEndProcessing = (backData: string, attributes: AttributeItemType[], botId?: number) => {
  const attrIndex = backData.indexOf('{{')
  const attrBackIndex = backData.indexOf('}}', attrIndex)

  if (attrIndex < 0 || attrBackIndex < 0) {
    return backData.replaceAll('\n', '<br>')
  }

  const helper1 = backData.split('')
  const helper2 = backData.split('')
  const attrText = helper1.splice(attrIndex + 2, attrBackIndex - attrIndex - 2).join('')
  const currentAttribute = attributes.find(attributeI => attributeI.name === attrText)

  if (currentAttribute === undefined && botId) {
    createAttribute(botId, { name: attrText })
  }

  // eslint-disable-next-line max-len
  const attrElStart = `<attr contenteditable="false" style="display: inline-block; color: white; border-radius: 50px; min-height: 15px; background-color: ${getAttributeColor(
    currentAttribute?.type,
  )}">`
  const attrElEnd = '</attr>'

  const attr = attrElStart + attrText + attrElEnd
  helper2.splice(attrIndex, attrBackIndex - attrIndex + 2, ...attr.split(''))
  backData = helper2.join('')

  if (backData.includes('{{') && backData.includes('}}')) {
    return backEndProcessing(backData, attributes, botId)
  } else {
    return backData.replaceAll('\n', '<br>')
  }
}
