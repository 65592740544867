export const styles = () => ({
  chatBubble: {
    padding: '8px 16px',
    fontSize: 14,
    background: 'rgba(90, 152, 247, 0.08)',
    display: 'inline-block',
    width: '300px',
  },
  container: {
    border: '1px solid #E5E5E5',
    borderRadius: 8,
    height: 'fit-content',
  },
})
