import { createJsonHeaders, logoutOnRequestOrResponseJson } from '../api'

async function _apiMethod(method: string, url: string, body?: any) {
  const options: RequestInit = {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: method,
  }

  if (body) {
    options.body = JSON.stringify(body)
  }

  return fetch(url, options)
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => {
      if (response?.data) {
        return response.data
      }

      if (response?.error) {
        throw response?.error
      }
    })
}

class Api {
  async get(url: string) {
    return _apiMethod('GET', url)
  }

  async post(url: string, body?: any) {
    return _apiMethod('POST', url, body)
  }

  async put(url: string, body?: any) {
    return _apiMethod('PUT', url, body)
  }

  async patch(url: string, body?: any) {
    return _apiMethod('PATCH', url, body)
  }

  async delete(url: string, body?: any) {
    return _apiMethod('DELETE', url, body)
  }
}

export default new Api()
