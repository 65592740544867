import { SAVE_USERS_STATISTICS } from '../actions/usersStatistics'

const usersStatistics = (state = null, action) => {
  if (action.type === SAVE_USERS_STATISTICS) {
    return action.stats
  } else {
    return state
  }
}

export default usersStatistics
