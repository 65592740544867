import React from 'react'
import SwitchM from '@material-ui/core/Switch'
import classes from './styles.module.scss'

interface Props {
  label?: string
  checked: boolean
  onChange: (a: any) => void
}

const Switch: React.FC<Props> = ({ label, checked, onChange }) => {
  return (
    <div className={classes.container}>
      <SwitchM checked={checked} color="primary" onChange={onChange} />
      {label && <p className={classes.inputTitle}>{label}</p>}
    </div>
  )
}

export default Switch
