import React, { useEffect, useRef, useState } from 'react'
import { LeftArrowIcon, RightArrowIcon } from '../../uiKit/icons/Icons'

import classes from './styles.module.scss'

const Pagination = ({ page, count, onChange }) => {
  const [value, setValue] = useState(null)
  const inputRef = useRef(null)

  useEffect(() => setValue(page), [page])

  const handleChangeLeft = () => {
    if (page !== 1) {
      onChange(page - 1)
    }
  }

  const handleChangeRight = () => {
    if (page < count) {
      onChange(page + 1)
    }
  }

  const handleChange = event => {
    const value = Number(event.target.value) || ''
    if (value || value === '') {
      setValue(value)
      inputRef.current.style.width = value.toString().length + 1 + 'em'
      if (value) {
        onChange(value)
      }
    }
  }

  const handleBlur = () => {
    setValue(page)
  }

  const handleInput = event => {
    if (Number(event.target.value) > count) {
      event.target.value = value
    }
  }

  return (
    <div className={classes.container}>
      <div onClick={handleChangeLeft} className={classes.button}>
        <LeftArrowIcon />
      </div>
      <div className={classes.inputHolder}>
        <input
          className={classes.input}
          value={value}
          ref={inputRef}
          onChange={handleChange}
          onBlur={handleBlur}
          onInput={handleInput}
        />
        <div className={classes.pagesText}> of {count}</div>
      </div>
      <div onClick={handleChangeRight} className={classes.button}>
        <RightArrowIcon />
      </div>
    </div>
  )
}

export default Pagination
