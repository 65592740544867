import React from 'react'

export const DeleteIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M7.33464 8.33333C7.79487 8.33333 8.16797 8.70642 8.16797 9.16666V14.1667C8.16797 14.6269 7.79487 15 7.33464 15C6.8744 15 6.5013 14.6269 6.5013 14.1667V9.16666C6.5013 8.70642 6.8744 8.33333 7.33464 8.33333Z" fill="#FF6666"/>
      {/* eslint-disable-next-line max-len */}
      <path d="M11.5013 14.1667V9.16666C11.5013 8.70642 11.1282 8.33333 10.668 8.33333C10.2077 8.33333 9.83464 8.70642 9.83464 9.16666V14.1667C9.83464 14.6269 10.2077 15 10.668 15C11.1282 15 11.5013 14.6269 11.5013 14.1667Z" fill="#FF6666"/>
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M4.83464 4.16666V3.33333C4.83464 2.67029 5.09803 2.0344 5.56687 1.56556C6.03571 1.09672 6.67159 0.833328 7.33464 0.833328H10.668C11.331 0.833328 11.9669 1.09672 12.4357 1.56556C12.9046 2.0344 13.168 2.67029 13.168 3.33333V4.16666H16.5013C16.9615 4.16666 17.3346 4.53976 17.3346 4.99999C17.3346 5.46023 16.9615 5.83333 16.5013 5.83333H15.668V16.6667C15.668 17.3297 15.4046 17.9656 14.9357 18.4344C14.4669 18.9033 13.831 19.1667 13.168 19.1667H4.83464C4.17159 19.1667 3.53571 18.9033 3.06687 18.4344C2.59803 17.9656 2.33464 17.3297 2.33464 16.6667V5.83333H1.5013C1.04106 5.83333 0.667969 5.46023 0.667969 4.99999C0.667969 4.53976 1.04106 4.16666 1.5013 4.16666H4.83464ZM6.74538 2.74407C6.90166 2.58779 7.11362 2.49999 7.33464 2.49999H10.668C10.889 2.49999 11.1009 2.58779 11.2572 2.74407C11.4135 2.90035 11.5013 3.11231 11.5013 3.33333V4.16666H6.5013V3.33333C6.5013 3.11231 6.5891 2.90035 6.74538 2.74407ZM4.0013 5.83333V16.6667C4.0013 16.8877 4.0891 17.0996 4.24538 17.2559C4.40166 17.4122 4.61362 17.5 4.83464 17.5H13.168C13.389 17.5 13.6009 17.4122 13.7572 17.2559C13.9135 17.0996 14.0013 16.8877 14.0013 16.6667V5.83333H4.0013Z" fill="#FF6666"/>
    </svg>
  )
}
