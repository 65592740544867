export const ComponentNames = Object.freeze({
  Analytics: 'Analytics',
  Flows: 'Flows',
  Dashboard: 'Dashboard',
  NLP: 'NLP',
  Support: 'Support',
  Broadcasts: 'Broadcasts',
  Widget: 'Widget',
  BotSettings: 'Bot Settings',
})
