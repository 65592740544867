import React, { FC, useState } from 'react'
import { connect } from 'react-redux'

import Chart from '../Chart'
import CommentsList from '../CommentsList'
import AnalyticsHeader from '../AnalyticsHeader'
import NoChartData from '../../../../uiKit/NoChartData'

import classes from './styles.module.scss'
import {AllAnalyticsType, AnalyticsType} from '../../../../models/AnalyticsType'

interface Props {
  analytics: AnalyticsType
  fromDate: string
  toDate: string
}

const AnalyticsWrap: FC<Props > = ({ analytics, fromDate, toDate }) => {
  const [selectedBar, setSelectedBar] = useState(null)

  return (
    <div className={classes.container}>
      <AnalyticsHeader />

      <div className={classes.chartWrap}>
        {analytics?.totalFeedbacks ? (
          <>
            {analytics?.distribution && (
              <Chart data={analytics.distribution} selectedBar={selectedBar} handleSelectedBar={setSelectedBar} />
            )}
            <CommentsList selectedBar={selectedBar} fromDate={fromDate} toDate={toDate} />
          </>
        ) : (
          <NoChartData text='No data during selected time range'/>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: { analytics: AllAnalyticsType }) => ({
  analytics: state.analytics.analytics,
})

export default connect(mapStateToProps)(AnalyticsWrap)
