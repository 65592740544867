import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from 'react-autocomplete'
import { getAttribute } from '../../tabs/settings/api/attributes'
import { withRouter } from 'react-router-dom'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { styles } from './styles'
import { connect } from 'react-redux'
import { BACKSPACE_KEY, DELETE_KEY, ENTER_KEY } from '../../constants/keyCodes'

const InputAutosuggest = props => {
  const {
    placeholder,
    attributeId,
    value,
    onChange,
    activeBotId,
    style,
    classes,
    containerStyle,
    menuStyle,
    onBlur,
    error,
  } = props
  const [suggestions, setAutoSuggest] = useState([])
  const [focus, setFocus] = useState(false)
  const [upward, setUpward] = useState(false)

  const autocompleteRef = useRef(null)

  //buttonPos?.top + buttonPos?.height
  useEffect(() => {
    if (attributeId) {
      getAttribute(activeBotId, attributeId, 'en').then(data => {
        setAutoSuggest(data || [])
      })
    }
  }, [attributeId])

  useEffect(() => {
    const menuPos = autocompleteRef?.current?.firstChild?.childNodes[1]?.getBoundingClientRect()
    setUpward(menuPos?.top + menuPos?.height > window.innerHeight)
  }, [focus])

  const handleKeyUp = event => {
    const key = event.keyCode || event.charCode

    if ((key === DELETE_KEY || key === BACKSPACE_KEY) && !value) {
      setFocus(true)
    }
  }

  const handleKeyPress = event => {
    const key = event.keyCode || event.charCode

    if (key === ENTER_KEY) {
      setFocus(!focus)
    }
  }

  const getItemValue = suggestion => suggestion

  const renderItem = (item, isHighlighted) => {
    return (
      <div style={{ background: isHighlighted ? '#dfecff' : 'white' }} className={classes.item}>
        {item}
      </div>
    )
  }

  const renderInput = props => {
    return (
      <input
        {...props}
        style={{
          border: error && !focus && '1px solid red',
          ...containerStyle,
        }}
        className={classes.input}
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        onKeyPress={handleKeyPress}
        onFocus={() => setFocus(true)}
        onBlur={handleBlur}
      />
    )
  }

  const renderMenu = items => {
    const style = {
      display: !items.length && 'none',
      bottom: upward && '46px',
      top: !upward && '46px',
    }

    return <div className={classes.menu} children={items} style={{ ...menuStyle, ...style }} />
  }

  const onSelect = value => {
    const newValue = {
      target: {
        value,
      },
    }

    onChange(newValue)
    setFocus(false)
  }

  const handleBlur = () => {
    if (onBlur) onBlur()
  }

  const filterSuggestions = suggestion => {
    return suggestion.toLowerCase().includes(value?.toLowerCase())
  }

  return (
    <div style={{ ...style }} className={classes.container} ref={autocompleteRef}>
      <ClickAwayListener onClickAway={() => setFocus(false)}>
        <Autocomplete
          getItemValue={getItemValue}
          items={suggestions.filter(filterSuggestions)}
          value={value || ''}
          open={focus}
          placeholder={placeholder}
          onChange={onChange}
          onSelect={onSelect}
          wrapperStyle={{ display: 'block' }}
          renderInput={renderInput}
          renderItem={renderItem}
          renderMenu={renderMenu}
        />
      </ClickAwayListener>
    </div>
  )
}

InputAutosuggest.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBotId: state.activeBot?.id,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(InputAutosuggest)))
