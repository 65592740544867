import React from 'react'

export const LanguageArrowDown = () => {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5.5 6L10 1" stroke="#1658F3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
