export const styles = () => ({
  container: {
    borderRadius: 10,
    border: '1px solid #EBEBEF',
    width: '100%',
    marginBottom: 20,
    position: 'relative',
    minWidth: 480,
    '&:hover': {
      '& i': {
        display: 'block',
      },
    },
  },
  header: {
    padding: '17px 24px 24px 24px',
    fontWeight: 700,
    color: 'rgba(58, 63, 98, 1)',
    borderBottom: '2px solid #EBEBEF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  deleteTemplate: {
    right: '28px',
    top: '-16px',
    position: 'absolute',
    display: 'none',
    transition: 'all 0.5s ease',
  },
  deleteIcon: {
    background: 'white',
    border: '1px solid #EBEBEF',
    boxShadow: '0px 3px 6px #EBEBEF',
    width: 40,
    height: 40,
    position: 'absolute',
    right: -40,
    display: 'block',
  },
  body: {
    padding: 24,
  },
  label: {
    fontSize: 14,
    color: '#354052',
    marginBottom: 8,
    '& span': {
      color: 'red',
    },
  },
  inputWrap: {
    borderTop: '1px solid #EBEBEF',
    paddingTop: 16,
    marginTop: 16,
  },
})
