import { frontDataProcessing } from './frontDataProcessing/frontDataProcessing'
import { getSelectionHtml } from './getSelectionHtml'

export const handleCtrlC = (event: any, isMenu: boolean) => {
  const charCode = String.fromCharCode(event.which).toLowerCase()
  if (((event.ctrlKey || event.metaKey) && charCode === 'c') || isMenu) {
    navigator.clipboard.writeText(frontDataProcessing(getSelectionHtml()))
    event.preventDefault()
  }
}
