import { RefObject } from 'react'

export const removeExtraBracketsAfterAttributeIsAdded = (
  inputRef: RefObject<HTMLElement>,
  newAttr: HTMLElement,
  attribute: string,
) => {
  inputRef.current.childNodes.forEach((nodeItem, index) => { //NOSONAR
    if (
      nodeItem === newAttr &&
      inputRef.current.childNodes[index - 1]?.nodeValue?.includes('{{') &&
      inputRef.current.childNodes[index - 1]?.nodeValue?.endsWith('}') &&
      !inputRef.current.childNodes[index - 1]?.nodeValue?.endsWith('}}') &&
      inputRef.current.childNodes[index + 1]?.nodeValue?.startsWith('}')
    ) {
      const prevNodeVal = inputRef.current.childNodes[index - 1]?.nodeValue
      const nextNodeVal = inputRef.current.childNodes[index + 1]?.nodeValue
      const indexOffOpenBrackets = prevNodeVal.indexOf('{{')
      if (index - 1 === 0 && prevNodeVal === '{{' + attribute + '}') {
        inputRef.current.childNodes[index - 1]?.remove()
        index -= 1
      } else {
        inputRef.current.childNodes[index - 1].nodeValue = prevNodeVal.substring(0, indexOffOpenBrackets)
      }
      if (nextNodeVal === '}' && inputRef.current.childNodes.length === 2) {
        inputRef.current.childNodes[index + 1]?.remove()
      } else {
        inputRef.current.childNodes[index + 1].nodeValue = nextNodeVal.substring(1, nextNodeVal.length)
      }
    } else if (
      nodeItem === newAttr &&
      inputRef.current.childNodes[index - 1]?.nodeValue?.includes('{{') &&
      inputRef.current.childNodes[index - 1]?.nodeValue?.endsWith('}}')
    ) {
      const prevNodeVal = inputRef.current.childNodes[index - 1]?.nodeValue
      const indexOffOpenBrackets = prevNodeVal.indexOf('{{')
      if (index - 1 === 0 && prevNodeVal === '{{' + attribute + '}}') {
        inputRef.current.childNodes[index - 1]?.remove()
        index -= 1
      } else {
        inputRef.current.childNodes[index - 1].nodeValue = prevNodeVal.substring(0, indexOffOpenBrackets)
      }
      if (inputRef.current.childNodes[index + 1] && inputRef.current.childNodes[index + 1]?.nodeValue === '') {
        inputRef.current.childNodes[index + 1]?.remove()
      }
    } else if (nodeItem === newAttr && inputRef.current.childNodes[index - 1]?.nodeValue?.includes('{{')) {
      const nodeVal = inputRef.current.childNodes[index - 1]?.nodeValue
      const indexOfOpenBrackets = nodeVal.indexOf('{{')
      inputRef.current.childNodes[index - 1].nodeValue = nodeVal.substring(0, indexOfOpenBrackets)
      if (inputRef.current.childNodes[index + 1] && inputRef.current.childNodes[index + 1]?.nodeValue === '') {
        inputRef.current.childNodes[index + 1]?.remove()
      }
      if (inputRef.current.childNodes[index - 1] && inputRef.current.childNodes[index - 1]?.nodeValue === '') {
        inputRef.current.childNodes[index - 1]?.remove()
      }
    }
  })
}
