import { BASE_URL } from '../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { setAttributes, addAttribute } from '../actions/attributes'

export function getAttributes(botId, lang) {
  return fetch(`${BASE_URL}/bot/${botId}/attributes?lang=${lang}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      if (json.data) setAttributes(json.data)
    })
    .catch(json => {
      alertError(json.error?.message)
    })
}

export function updateAttributes(botId, attributes, lang) {
  return fetch(`${BASE_URL}/bot/${botId}/attributes?lang=${lang}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(attributes),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setAttributes(json.data)
    })
    .catch(json => {
      alertError(json.error?.message)
    })
}

export function getAttribute(botId, attributeId, lang) {
  return fetch(
    `${BASE_URL}/bot/${botId}/attributes/${attributeId}/values?lang=${lang}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      return json.data
    })
    .catch(json => {
      alertError(json.error?.message)
    })
}

export function createAttribute(botId, attribute) {
  return fetch(`${BASE_URL}/bot/${botId}/attributes`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(attribute),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      addAttribute(json.data)
      return json.data
    })
    .catch(json => {
      alertError(json.error?.message)
    })
}
