import { RefObject } from 'react'

export const addAttributeToAfterTag = (
  newAttr: HTMLElement,
  inputRef: RefObject<HTMLElement>,
  caretNodePos: number,
) => {
  inputRef.current.childNodes.forEach((nodeItem, index) => {
    if (index === caretNodePos - 1) {
      inputRef.current.insertBefore(newAttr, nodeItem.nextSibling)
    }
  })
}
