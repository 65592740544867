export const styles = () => ({
  title: {
    height: 32,
    color: '#B0B2C0',
    padding: '8px 12px',
    textTransform: 'uppercase',
    fontSize: 12,
    cursor: 'pointer',
  },
  chevron: {
    marginLeft: '8px',
    maxHeight: '100%',
    transition: '.1s ease-in-out',
  },
  dialogsContainer: {},
})
