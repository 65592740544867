// @ts-nocheck
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'

import Heading from '../../../../uiKit/texts/Heading'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import LaguageSelector from '../../../../uiKit/LaguageSelector/LaguageSelector'
import SecondaryButton from '../../../../uiKit/buttons/SecondaryButton/SecondaryButton'
import GreetingsTable from '../GreetingsTable'
import { SPUN_PEARL_GREY } from '../../../../constants/colors'
import DeleteGreetingModal from '../DeleteGreetingModal'
import EditDefaultModal from '../EditDefaultModal'
import AddGreetingModal from '../AddGreetingModal'
import EditGreetingModal from '../EditGreetingModal'

import classes from './styles.module.scss'
import {GreetingType, WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'

interface Props {
  widgetSettings: WidgetSettingsType
}

const GreetingTab: React.FC<Props> = ({ widgetSettings }) => {
  const [selectedLanguage, setSelectedLanguages] = useState({ label: 'English', value: 'en' })
  const [selectedGreeting, setSelectedGreeting] = useState<GreetingType | null>(null)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openEditModal, setEditDeleteModal] = useState(false)
  const [openEditDefaultModal, setEditDefaultDeleteModal] = useState(false)

  const greetings = useMemo(
    () => widgetSettings?.languageConfigs[selectedLanguage.value]?.greetings,
    [widgetSettings?.languageConfigs[selectedLanguage.value]?.greetings],
  )

  const handleToggleAddGreetingModal = (open: boolean) => {
    setOpenAddModal(open)
  }

  const handleToggleEditGreetingModal = (open: boolean, selectedGreeting: GreetingType) => {
    setSelectedGreeting(selectedGreeting)
    setEditDeleteModal(open)
  }

  const handleToggleEditDefaultGreetingModal = (open: boolean, selectedGreeting: GreetingType) => {
    setSelectedGreeting(selectedGreeting)
    setEditDefaultDeleteModal(open)
  }

  const handleToggleDeleteGreetingModal = (open: boolean, selectedGreeting: GreetingType) => {
    setSelectedGreeting(selectedGreeting)
    setOpenDeleteModal(open)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Heading>Greeting</Heading>
        <LaguageSelector onChange={setSelectedLanguages} value={selectedLanguage} />
      </div>

      <Paragraph style={{ color: SPUN_PEARL_GREY }}>
        Customize greeting message that will pop up automatically to the user based on his language
      </Paragraph>

      <div className={classes.body}>
        <SecondaryButton title="Add greeting" onClick={() => handleToggleAddGreetingModal(true)} />

        <GreetingsTable
          greetings={greetings}
          selectedLanguage={selectedLanguage}
          onToggleEditModal={handleToggleEditGreetingModal}
          onToggleDeleteModal={handleToggleDeleteGreetingModal}
          onToggleEditDefaultModal={handleToggleEditDefaultGreetingModal}
        />

        {openDeleteModal && (
          <DeleteGreetingModal
            open={openDeleteModal}
            selectedGreeting={selectedGreeting}
            selectedLanguage={selectedLanguage}
            onClose={() => handleToggleDeleteGreetingModal(false, null)}
          />
        )}

        {openEditDefaultModal && (
          <EditDefaultModal
            open={openEditDefaultModal}
            selectedGreeting={selectedGreeting}
            selectedLanguage={selectedLanguage}
            onClose={() => handleToggleEditDefaultGreetingModal(false, null)}
          />
        )}

        {openEditModal && (
          <EditGreetingModal
            open={openEditModal}
            selectedGreeting={selectedGreeting}
            selectedLanguage={selectedLanguage}
            onClose={() => handleToggleEditGreetingModal(false, null)}
          />
        )}

        {openAddModal && (
          <AddGreetingModal
            open={openAddModal}
            selectedLanguage={selectedLanguage}
            onClose={() => handleToggleAddGreetingModal(false)}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default connect(mapStateToProps)(GreetingTab)
