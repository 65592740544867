export const styles = () => ({
  addButton: {
    color: 'rgba(22, 88, 243, 1)',
    fontWeight: 700,
    fontSize: 14,
    cursor: 'pointer',
    marginTop: 8,
    width: 'fit-content',
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: 'rgba(53, 64, 82, 1)',
    margin: '24px 0px 12px 0px',
  },
  attributeWrap: {
    display: 'flex',
    marginTop: 8,
    gap: '8px',
    position: 'relative',
    paddingRight: 26,
  },
  inputValue: {
    border: '1px solid rgba(53, 64, 82, 0.1)',
    outline: 'none',
    height: 38,
    borderRadius: 4,
    width: '128px',
    padding: '8px 12px',
    color: 'rgba(53, 64, 82, 0.7)',
    marginLeft: 8,
  },
  iconWrap: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    position: 'absolute',
    right: '-3px',
    top: 6,
  },
  bottomScrollElem: {
    float: 'left',
    clear: 'both',
  },
})
