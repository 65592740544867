import React from 'react'
import MTPopover from '@material-ui/core/Popover'

const Popover = ({ children, id, open, anchorEl, onClose }) => {
  return (
    <MTPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      {children}
    </MTPopover>
  )
}

export default Popover
