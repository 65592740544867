import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { saveBroadcasts } from '../actions/broadcasts'
import { BASE_URL } from '../../../configs'

export function createBroadcast(broadcast) {
  fetch(BASE_URL + '/broadcast', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(broadcast),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Broadcast is created successfully.')
      loadBroadcasts(broadcast.botId)
    })
    .catch(function(error) {
      alertError('Fail. Try it later', 10000)
    })
}

export function loadBroadcasts(botId) {
  fetch(BASE_URL + '/broadcast/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveBroadcasts(json)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!', 5000)
    })
}

export function cancelBroadcast(broadcastId) {
  fetch(BASE_URL + '/broadcast/' + broadcastId + '/cancel', {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PATCH',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveBroadcasts(json)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support!', 5000)
    })
}
