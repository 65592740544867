import React, { useEffect, useMemo, useState } from 'react'

import Input from '../../../../uiKit/inputs/Input'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import ConditionExpression from '../../../flowBuilder/components/ConditionExpression/ConditionExpression'
import { generateId } from '../../../../helpers/generateId'
import { usePrevious } from '../../../../hooks/usePrevious'
import { setErrors } from '../../../../helpers/setErrors/setErrors'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { GreetingType } from '../../../../models/WidgetSettingsTypes'
import { ExpressionType } from '../../../../models/MessageTypes'

interface Props {
  greeting: GreetingType
  onChange: (a: GreetingType) => void
  saveTime: Date
}

const GeneralTab: React.FC<Props> = ({ greeting, onChange, saveTime }) => {
  const [errorsName, setErrorsName] = useState<string[]>([])
  const greetingCopy = useMemo(() => deepCopyFunction(greeting), [greeting])
  const prevSaveTime = usePrevious(saveTime)

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      validateName(greeting.name)
    }
  }, [saveTime])

  const handleUpdateName = (value: string) => {
    validateName(value)
    greetingCopy.name = value
    onChange(greetingCopy)
  }

  const handleUpdateConjunction = (e: any) => {
    greetingCopy.condition.conjunction = e.value
    onChange(greetingCopy)
  }

  const handleDeleteExpression = (index: number) => {
    greetingCopy.condition.expressions.splice(index, 1)
    onChange(greetingCopy)
  }

  const handleUpdateExpression = (expression: ExpressionType, index: number) => {
    greetingCopy.condition.expressions[index] = expression
    onChange(greetingCopy)
  }

  const handleAddExpression = () => {
    greetingCopy.condition.expressions.push({
      attribute: null,
      operator: 'IS',
      value: '',
      id: generateId(),
    })

    onChange(greetingCopy)
  }

  const validateName = (value: string) => {
    const errors = setErrors(value, 20)
    setErrorsName(errors)

    return !errors.length
  }

  return (
    <>
      <Input
        title="Greeting name"
        placeholder="Enter greeting name"
        value={greeting.name || ''}
        onChange={(event: any) => handleUpdateName(event.target.value)}
        onBlur={() => validateName(greeting.name)}
        error={!!errorsName.length}
        errorMessage={errorsName[0]}
      />

      <Paragraph style={{ color: '#3A3F62', margin: '24px 0 8px' }}>Condition</Paragraph>

      {greeting?.condition?.expressions?.map((expression, index) => (
        <ConditionExpression
          key={expression.id}
          expression={expression}
          isLast={greeting?.condition?.expressions?.length === index + 1}
          isOne={greeting?.condition?.expressions?.length === 1}
          conjunction={greeting?.condition?.conjunction}
          onUpdateConjunction={handleUpdateConjunction}
          onDelete={() => handleDeleteExpression(index)}
          onUpdate={(expression: ExpressionType) => handleUpdateExpression(expression, index)}
          onAdd={handleAddExpression}
          saveTime={saveTime}
        />
      ))}
    </>
  )
}

export default GeneralTab
