import React, { useMemo } from 'react'

import HeaderField from '../HeaderField/HeaderField'
import ResponseMappingField from '../ResponseMappingField/ResponseMappingField'
import { PlusIcon } from '../../../../uiKit/icons/PlusIcon'

import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { generateId } from '../../../../helpers/generateId'

import classes from './styles.module.scss'
import { OptionMessageItemType } from 'models/MessageTypes'

interface Props {
  items: OptionMessageItemType[] | null
  onChange: (a: any) => void
  isHeaders: boolean
}

const ItemsListTab: React.FC<Props> = ({ items, onChange, isHeaders }) => {
  const itemsCopy = useMemo(() => deepCopyFunction(items), [items])

  const handleAddItem = () => {
    itemsCopy.push({ key: '', value: '', id: generateId() })
    onChange(itemsCopy)
  }

  const handleUpdateItem = (item, index) => {
    itemsCopy[index] = item
    onChange(itemsCopy)
  }

  const handleDeleteItem = index => {
    itemsCopy.splice(index, 1)
    onChange(itemsCopy)
  }

  return (
    <div className={classes.container}>
      {!!items.length && (
        <div className={classes.labels}>
          <span>{isHeaders ? 'Key' : 'Custom field'}</span>
          <span>{isHeaders ? 'Value' : 'JSON Path'}</span>
        </div>
      )}
      {items.map((item, index) =>
        (isHeaders ? (
          <HeaderField
            header={item}
            key={item.id}
            onChange={item => handleUpdateItem(item, index)}
            onDelete={() => handleDeleteItem(index)}
          />
        ) : (
          <ResponseMappingField
            key={item.id}
            field={item}
            onChange={item => handleUpdateItem(item, index)}
            onDelete={() => handleDeleteItem(index)}
            index={index}
          />
        )),
      )}

      <div className={classes.addButton} onClick={handleAddItem}>
        <PlusIcon />
        {isHeaders ? 'Request header' : 'Add JSONPath'}
      </div>
    </div>
  )
}

export default ItemsListTab
