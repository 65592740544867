/* eslint-disable */
import React from 'react'

export const AttachmentIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none">
      <path
        d="M20.4403 11.05L11.2503 20.24C10.1244 21.3658 8.59747 21.9983 7.00529 21.9983C5.41311 21.9983 3.88613 21.3658 2.76029 20.24C1.63445 19.1141 1.00195 17.5872 1.00195 15.995C1.00195 14.4028 1.63445 12.8758 2.76029 11.75L11.9503 2.55998C12.7009 1.80942 13.7188 1.38776 14.7803 1.38776C15.8417 1.38776 16.8597 1.80942 17.6103 2.55998C18.3609 3.31054 18.7825 4.32852 18.7825 5.38998C18.7825 6.45144 18.3609 7.46942 17.6103 8.21998L8.41029 17.41C8.03501 17.7853 7.52602 17.9961 6.99529 17.9961C6.46456 17.9961 5.95557 17.7853 5.58029 17.41C5.20501 17.0347 4.99418 16.5257 4.99418 15.995C4.99418 15.4643 5.20501 14.9553 5.58029 14.58L14.0703 6.09998"
        stroke="#354052"
        strokeOpacity="0.7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
