import React from 'react'
import classes from './styles.module.scss'
import Divider from 'uiKit/Divider'

interface Props {
  conversationId: string
}

const ConversationIdLine: React.FC<Props> = ({ conversationId }) => {
  return (
    <div className={classes.container}>
      <Divider margin={23} />
      <div className={classes.id}>ID #{conversationId}</div>
    </div>
  )
}

export default ConversationIdLine
