import { isStringEmpty } from '../isStringEmpty'
import { OptionItemType } from '../../models/MessageTypes'

export const setErrors = (
  value: string | number | OptionItemType[] | number[],
  maxLength?: number,
  usedNames?: string[],
): string[] => {
  const errors = []
  const formatValue = value.toString()

  if (Array.isArray(value)) {
    if (!value.length) errors.push('This field can’t be empty')
  } else {
    if (isStringEmpty(formatValue)) {
      errors.push('This field can’t be empty')
    }
    if (maxLength !== undefined && formatValue.length > maxLength) {
      errors.push(`Up to ${maxLength} symbols is allowed`)
    }
    if (usedNames?.length && usedNames.includes(<string>value)) {
      errors.push('This field name should be unique')
    }
  }

  return errors
}
