import React, { FC } from 'react'
import { connect } from 'react-redux'

import UserIcon from '../../../../uiKit/UserIcon'
import { ExternalLinkIcon } from '../../../../uiKit/icons/ExternalLinkIcon'
import { HOME_PATH } from '../../../../configs'

import classes from './styles.module.scss'
import {ConversationType} from '../../../../models/FunnelTypes'

interface Props {
  conversation: ConversationType
  botId: number
}

const UserInfo: FC<Props> = ({ conversation, botId }) => {
  const handleOpenLink = () => {
    const link = `${HOME_PATH}/bot/${botId}/support/${conversation.userId}#${conversation.messageId}`
    window.open(link, '_blank')
  }

  return (
    <div className={classes.container}>
      <UserIcon image={conversation?.profilePicture} platform={conversation?.platform} size={40}/>
      <div className={classes.title}>
        {conversation?.firstName} {conversation?.lastName}
      </div>
      <div className={classes.button} onClick={handleOpenLink}>
        <ExternalLinkIcon />
      </div>
    </div>
  )
}

const mapStateToProps = (state: { activeBot: { id: any } }) => ({
  botId: state.activeBot?.id
})

export default connect(mapStateToProps)(UserInfo)
