import { CrossIcon } from '../../../../uiKit/icons/CrossIcon'
import { WHITE } from '../../../../constants/colors'
import classes from './styles.module.scss'
import {DefaultSettingsType, LanguageConfigsType} from '../../../../models/WidgetSettingsTypes'
import React from 'react'

interface Props {
  languageConfigs: LanguageConfigsType
  defaultSettings: DefaultSettingsType
}

const StartWidgetScreen: React.FC<Props> = ({ languageConfigs, defaultSettings }) => {
  const {
    imageUrl,
    color,
    welcomeTitle,
    welcomeSubtitle,
    startLabel,
    isLoginNameInputEnabled,
    isLoginEmailInputEnabled,
  } = languageConfigs

  return (
    <div className={classes.container}>
      <div className={classes.header} style={{ backgroundColor: color }}>
        <div className={classes.nameWrap}>
          <img src={imageUrl} alt="logo" className={classes.logo} />
          <div className={classes.titleWrap}>
            <p className={classes.title}>{welcomeTitle}</p>
            <p className={classes.greetingText}>{welcomeSubtitle}</p>
          </div>
        </div>
        <div className={classes.iconWrap}>
          <CrossIcon color={WHITE} />
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.label}>{startLabel}</div>
        {isLoginNameInputEnabled && (
          <div className={classes.inputWrap}>
            <div className={classes.input}>{defaultSettings.nameInputPlaceholder}</div>
            {isLoginNameInputEnabled && !isLoginEmailInputEnabled && (
              <div className={classes.buttonInput} style={{ backgroundColor: color }}>
                {defaultSettings.startButton}
              </div>
            )}
          </div>
        )}
        {isLoginEmailInputEnabled && (
          <div className={classes.inputWrap}>
            <div className={classes.input}>{defaultSettings.emailInputPlaceholder}</div>
            {!isLoginNameInputEnabled && isLoginEmailInputEnabled && (
              <div className={classes.buttonInput} style={{ backgroundColor: color }}>
                {defaultSettings.startButton}
              </div>
            )}
          </div>
        )}
        {isLoginNameInputEnabled && isLoginEmailInputEnabled && (
          <div className={classes.button} style={{ backgroundColor: color }}>
            {defaultSettings.startButton}
          </div>
        )}
      </div>
    </div>
  )
}

export default StartWidgetScreen
