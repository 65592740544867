export const styles = () => ({
  container: {
    width: '100%',
    height: 72,
    borderBottom: '1px solid #EBEBEF',
    padding: '18px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  assignedStatus: {
    color: '#11C314',
    fontSize: 14,
    margin: '0px 16px',
  },
  assignedStatusWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  notification: {
    color: 'rgba(53, 64, 82, 0.7)',
    fontSize: 14,
    margin: '0px 16px',
  },
  blockWrap: {
    display: 'flex',
    alignItems: 'center',
  },
})
