import React, { FC, useMemo } from 'react'
import { connect } from 'react-redux'

import classes from './styles.module.scss'
import {AllAnalyticsType, AnalyticsType} from '../../../../models/AnalyticsType'

interface Props {
  analytics: AnalyticsType
}

const AnalyticsHeader: FC<Props> = ({ analytics }) => {
  const score = useMemo(() => Math.round(analytics?.averageScore * 10) / 10, [analytics?.averageScore])

  return (
    <div className={classes.header}>
      <p className={classes.title}>Ratings and Reviews</p>

      <div>
        <p className={classes.rateWrap}>
          Average rating
          <span>
            {score}/5 <span> ★ </span>
          </span>
        </p>
        <p className={classes.rateWrap}>
          Ratings
          <span> {analytics?.totalFeedbacks}</span>
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { analytics: AllAnalyticsType }) => ({
  analytics: state.analytics.analytics,
})

export default connect(mapStateToProps)(AnalyticsHeader)
