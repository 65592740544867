import React, { useEffect, useMemo, useState } from 'react'

import Input from '../../../../uiKit/inputs/Input'
import RadioButtonGroup from '../../../../uiKit/RadioButtonGroup'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { setErrors } from '../../../../helpers/setErrors/setErrors'
import { usePrevious } from '../../../../hooks/usePrevious'
import { GreetingType } from '../../../../models/WidgetSettingsTypes'
import { OptionItemType } from '../../../../models/MessageTypes'

interface Props {
  greeting: GreetingType
  onChange: (a: GreetingType) => void
  saveTime: Date
}

const CustomizationTab: React.FC<Props> = ({ greeting, onChange, saveTime }) => {
  const [errorsGreetingText, setErrorsGreetingText] = useState<string[]>([])
  const [errorsDelaySeconds, setErrorsDelaySeconds] = useState<string[]>([])
  const [atomSelectTouched, setAtomSelectTouched] = useState(false)

  const greetingCopy = useMemo(() => deepCopyFunction(greeting), [greeting])
  const prevSaveTime = usePrevious(saveTime)

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      validateGreetingText(greeting.greetingText)
      validateDelayTime(greeting.delaySeconds)
      setAtomSelectTouched(true)
    }
  }, [saveTime])

  const handleUpdateGreetingText = (value: string) => {
    greetingCopy.greetingText = value
    onChange(greetingCopy)
  }

  const handleUpdateDelaySeconds = (value: string) => {
    greetingCopy.delaySeconds = value
    onChange(greetingCopy)
  }

  const handleUpdateStartAtom = ({ value }: OptionItemType) => {
    greetingCopy.startAtomId = value
    onChange(greetingCopy)
  }

  const validateGreetingText = (value: string) => {
    const errors = setErrors(value)
    setErrorsGreetingText(errors)

    return !errors.length
  }

  const validateDelayTime = (value: string | number) => {
    const errors = setErrors(value)
    setErrorsDelaySeconds(errors)

    return !errors.length
  }

  return (
    <>
      <Input
        title="Greeting text"
        placeholder="Enter greeting text"
        value={greeting.greetingText || ''}
        onChange={(event: any) => handleUpdateGreetingText(event.target.value)}
        onBlur={() => validateGreetingText(greeting.greetingText)}
        error={!!errorsGreetingText.length}
        errorMessage={errorsGreetingText[0]}
      />
      <RadioButtonGroup
        title="Delay time"
        value={greeting.delaySeconds}
        onChange={handleUpdateDelaySeconds}
        onBlur={() => validateDelayTime(greeting.delaySeconds)}
        errors={errorsDelaySeconds}
      />
      <Paragraph style={{ color: '#3A3F62', margin: '24px 0 8px' }}>Start atom</Paragraph>
      <AtomSelect value={greeting.startAtomId} onChange={handleUpdateStartAtom} touched={atomSelectTouched} />
    </>
  )
}

export default CustomizationTab
