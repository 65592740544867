import React, {MouseEventHandler} from 'react'

import InputWithParams from '../../../../uiKit/InputWithParams/InputWithParams'
import HoverButton from '../../../../uiKit/buttons/HoverButton/HoverButton'

import classes from './styles.module.scss'

interface Props {
  addUserData: boolean
  body: string
  scrollBlock: any
  onChange(body: string): void
  onCheck: MouseEventHandler<HTMLDivElement>
}

const BodyTab: React.FC<Props> = ({ addUserData, body, scrollBlock, onChange, onCheck }) => {
  return (
    <div className={classes.container}>
      <InputWithParams
        placeholder="1 Enter request body"
        onChange={onChange}
        value={body || ''}
        scrollBlock={scrollBlock}
        styles={{
          height: 280,
          fontSize: 12,
        }}
        hoverButton={<HoverButton onChange={onCheck} title={'Add full user data'} value={addUserData} />}
      />
    </div>
  )
}

export default BodyTab
