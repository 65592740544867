/**
 * Created by Admin on 13.02.2018.
 */
import React from 'react'
import { connect } from 'react-redux'
import GenericTemplate from './GenericTemplate'
import ListTemplate from './ListTemplate'

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  isGeneric() {
    return this.props.message.attachment.payload.template_type === 'generic'
  }

  isList() {
    return this.props.message.attachment.payload.template_type === 'list'
  }

  render() {
    return (
      <div>
        {this.isList() && (
          <ListTemplate
            message={this.props.message}
            updateMessage={message => this.props.updateMessage(message)}
            onDelete={() => this.props.onDelete()}
            scrollBlock={this.props.scrollBlock}
          />
        )}
        {this.isGeneric() && (
          <GenericTemplate
            message={this.props.message}
            rtl={this.props.rtl}
            updateMessage={message => this.props.updateMessage(message)}
            onDelete={() => this.props.onDelete()}
            scrollBlock={this.props.scrollBlock}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  params: state.attributes,
})
export default connect(mapStateToProps)(Template)
