/* eslint-disable */
import React from 'react'

export const GalleryIcon = props => {
  // eslint-disable-next-line react/prop-types
  const { width, height, color } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M9.60948 2.39052C9.35943 2.14048 9.02029 2 8.66667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H8.66667C9.02029 14 9.35943 13.8595 9.60948 13.6095C9.85952 13.3594 10 13.0203 10 12.6667V3.33333C10 2.97971 9.85952 2.64057 9.60948 2.39052Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2761 2.39052C18.0261 2.14048 17.687 2 17.3333 2H14C13.6464 2 13.3072 2.14048 13.0572 2.39052C12.8071 2.64057 12.6667 2.97971 12.6667 3.33333V12.6667C12.6667 13.0203 12.8071 13.3594 13.0572 13.6095C13.3072 13.8595 13.6464 14 14 14H17.3333C17.687 14 18.0261 13.8595 18.2761 13.6095C18.5262 13.3594 18.6667 13.0203 18.6667 12.6667V3.33333C18.6667 2.97971 18.5262 2.64057 18.2761 2.39052Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2 8.66667H10" stroke={color} />
        <path d="M12.6667 8.66667H20.6667" stroke={color} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
