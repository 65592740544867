export const styles = () => ({
  labels: {
    display: 'flex',
    '& p': {
      width: '204px',
      fontSize: 14,
      fontWeight: '700',
      color: '#354052',
    },
  },
})
