import React from 'react'
import { connect } from 'react-redux'

import DownloadButton from '../../../../uiKit/buttons/DownloadButton'

interface Props {
  usersStatistics: any
}

const convertCircleChartDataCsv = chartData => {
  const pieChartData = {}

  for (const date in chartData.points) {
    chartData.points[date].forEach(({ platform, userCount }) => {
      if (platform !== 'All channels') {
        pieChartData[platform] = userCount + (pieChartData[platform] || 0)
      }
    })
  }

  const formattedPieChartData = Object.entries(pieChartData)
    .sort((a: [string, number], b: [string, number]) => b[1] - a[1])

  if (formattedPieChartData.length > 4) {
    return getChartDataWithOther(formattedPieChartData)
  }

  return Object.entries(pieChartData)
}

const getChartDataWithOther = formattedPieChartData => {
  const mainPlatforms = formattedPieChartData.slice(0, 4)
  const otherPlatforms = formattedPieChartData.slice(4)
  const otherPlatformsCount = otherPlatforms.reduce((a, [, userCount]) => a + userCount, 0)

  return [...mainPlatforms, ['Other', otherPlatformsCount]]
}

const ChannelsChartButton: React.FC<Props> = ({ usersStatistics: chartData }) => {
  return (
    <DownloadButton
      csvData={chartData}
      csvHeaders={['Channel', 'Number']}
      csvFilename={'users_per_channel'}
      csvOnDownload={convertCircleChartDataCsv}
    />
  )
}

const mapStateToProps = state => ({
  usersStatistics: state.usersStatistics,
})

export default connect(mapStateToProps)(ChannelsChartButton)
