import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { customStyles, styles } from './styles'
import Select, { components } from 'react-select'

const ValidationSelect = props => {
  const { value, onChange, touched, onFocus, classes } = props

  const options = [
    { label: 'None', value: 'NONE', description: null },
    { label: 'Number', value: 'NUMBER', description: '1234567890' },
    { label: 'Email', value: 'EMAIL', description: 'example@mail.com' },
    { label: 'Phone', value: 'PHONE', description: '+12345678901' },
    { label: 'Date', value: 'DATE', description: 'yyyymmdd' },
    { label: 'Time', value: 'TIME', description: 'hh:mm' },
    { label: 'Custom validation', value: 'CUSTOM' },
  ]

  const getValue = value => {
    return options.find(option => option.value === value)
  }

  const Option = props => {
    return (
      <components.Option {...props}>
        <div className={classes.optionWrap}>
          <div>{props.data.label}</div>
          <div className={classes.optionDescription}>
            {props.data.description}
          </div>
        </div>
      </components.Option>
    )
  }

  return (
    <Select
      placeholder={'None'}
      options={options}
      styles={customStyles}
      value={getValue(value)}
      onChange={onChange}
      maxMenuHeight={200}
      menuPlacement={'auto'}
      components={{ Option }}
      touched={touched}
      onFocus={onFocus}
    />
  )
}
ValidationSelect.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ValidationSelect)
