export const styles = () => ({
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 36,
    outline: 'none',
    border: '1px solid rgba(53, 64, 82, 0.1)',
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: '8px 38px 8px 12px',
  },
  icon: {
    position: 'absolute',
    top: 4,
    right: 12,
  },
})
