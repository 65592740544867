export const styles = () => ({
  arrow: {
    marginLeft: 10
  },
})

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 210,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#1658F3',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#1658F3',
    display: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#1658F3',
    },
  }),
}
