import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { SearchIcon } from '../icons/SearchIcon'

const Search = props => {
  const { classes, value, onChange, style, placeholder, onKeyDown } = props

  return (
    <div className={classes.container} style={style}>
      <input
        className={classes.input}
        placeholder={placeholder || 'Search'}
        value={value}
        onChange={onChange}
        onKeyDown={event => onKeyDown && onKeyDown(event)}
      />
      <div className={classes.icon}>
        <SearchIcon />
      </div>
    </div>
  )
}
Search.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Search)
