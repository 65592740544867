import { deepCopyFunction } from './deepCopyFunction'
import { CONDITIONAL_REDIRECT_TYPE, API_CALL_TYPE } from '../tabs/flowBuilder/constants/messageTypes'
import { isObjectEmpty } from './isObjectEmpty'

export const formatAtomSave = atom => {
  const atomCopy = deepCopyFunction(atom)

  if (atomCopy?.messages) {
    atomCopy.messages = atomCopy.messages.map(message => {
      if (message.type === CONDITIONAL_REDIRECT_TYPE) {
        const { conditionalRedirect } = message

        if (message.conditionalRedirect) {
          message.conditionalRedirect = _formatConditionRedirect(conditionalRedirect)
        }
      }

      if (message.type === API_CALL_TYPE) {
        if (message.apiCall) {
          message.apiCall = formatApiCall(message.apiCall)
        }
      }
      return message
    })
  }

  return atomCopy
}

export const formatApiCall = apiCall => {
  const { addUserData, body, headers, method, url, attributes } = deepCopyFunction(apiCall)

  const apiCallCopy = {
    addUserData,
    body,
    method,
    url,
  }

  const formatAttributes = attributes
    ?.filter(cur => cur.key)
    .reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})

  const formatHeaders = headers?.filter(cur => cur.key).reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})

  if (formatAttributes && !isObjectEmpty(formatAttributes)) {
    apiCallCopy.attributes = formatAttributes
  }

  if (formatHeaders && !isObjectEmpty(formatHeaders)) {
    apiCallCopy.headers = formatHeaders
  }

  return apiCallCopy
}

const _formatConditionRedirect = conditionalRedirect => {
  const { defaultRedirect, conditionGroups } = conditionalRedirect

  return {
    defaultRedirect,
    conditionGroups: conditionGroups?.map(_formatCondition),
  }
}

const _formatCondition = condition => {
  const { conjunction, redirectTo, priority, expressions } = condition

  return {
    conjunction,
    redirectTo,
    priority,
    expressions: expressions?.map(_formatConditionExpression),
  }
}

const _formatConditionExpression = expression => {
  const { attribute, operator, value } = expression

  return {
    attribute,
    operator,
    value,
  }
}
