import React, { FC, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import HeadlineWrapWithDateControl from '../../../../uiKit/HeadlineWrapWithDateControl'
import AnalyticsWrap from '../AnalyticsWrap'
import FunnelsWrap from '../FunnelsWrap'

import { getRatingDistribution, getComments } from '../../api/analytics'
import { getFunnels } from '../../api/funnels'
import { getBot } from '../../../home/api/bots'
import { getFlows } from '../../../flowBuilder/api/flow'

import classes from './styles.module.scss'
import LoaderScreen from '../../../../uiKit/loaders/loaderScreen'
import { clearFunnelsState } from '../../actions/funnels'
import {AllAnalyticsType} from '../../../../models/AnalyticsType'
import {FunnelType} from '../../../../models/FunnelTypes'

interface Props {
  match: any
  analytics: AllAnalyticsType
  funnels: FunnelType[]
}

const Analytics: FC<Props> = ({ match, analytics, funnels }) => {
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [loader, setLoader] = useState(true)
  const containerEndRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (analytics?.analytics !== null) {
      setLoader(false)
    }
  }, [analytics, funnels])

  useEffect(() => {
    getBot(match.params.botId)
    getFlows(match.params.botId)
    return clearFunnelsState
  }, [])

  const handleScrollToBottom = () => {
    if (containerEndRef.current) {
      containerEndRef.current.scrollIntoView()
    }
  }

  return (
    <div className={loader ? classes.containerLoader : classes.container} ref={containerRef}>
      <HeadlineWrapWithDateControl
        title="Analytics"
        botId={match.params.botId}
        loadData={[getRatingDistribution, getComments, getFunnels]}
        setFromDateCallback={setFromDate}
        setToDateCallback={setToDate}
      />
      {loader ? (
        <LoaderScreen />
      ) : (
        <>
          <AnalyticsWrap fromDate={fromDate} toDate={toDate} />

          <FunnelsWrap fromDate={fromDate} toDate={toDate} scrollToBottom={handleScrollToBottom} />
        </>
      )}
      <div ref={containerEndRef} />
    </div>
  )
}

const mapStateToProps = (state: { analytics: AllAnalyticsType; funnels: FunnelType[] }) => ({
  analytics: state.analytics,
  funnels: state.funnels,
})

export default withRouter(connect(mapStateToProps)(Analytics))
