import {
  saveContextsSize,
  addContextsSize,
  deleteOldContexstSize,
} from '../actions/contextSize'
import { BASE_URL } from '../../../configs'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api/index'
import {
  addContext,
  removeContext,
  saveContexts,
  updateExistedContext,
} from '../actions/contexts'

export function getContextsPages(botId, page) {
  return fetch(BASE_URL + '/context/bot/' + botId + '?page=' + page, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveContextsSize(json.contextSize)
          saveContexts(json.contextDTOList)
          return json.contextDTOList
        })
      }
      response.text().then(alertSuccess)
    })
    .catch(e => {
      alertError(
        'Sorry but something going wrong at getting triggers please ping support!',
      )
    })
}

export function getAtomContexts(atomId) {
  fetch(BASE_URL + '/context/atom/' + atomId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(logoutOnRequestOrResponseJson)
    .then(saveContexts)
    .catch(function(e) {
      alertError(
        'Sorry but something going wrong at getting triggers please ping support!',
      )
    })
}

export function getIntentContexts(intentId) {
  fetch(BASE_URL + '/context/intent/' + intentId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveContexts(json)
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting triggers please ping support!',
      )
    })
}

export function getContext(contextId, self) {
  fetch(BASE_URL + '/context/' + contextId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      self.setState({
        context: json,
      })
      return json
    })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting trigger please ping support!',
      )
    })
}

export function createNewContext(context, botId, contextSize) {
  fetch(BASE_URL + '/context/bot/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(context),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Trigger is created successfully.')
      addContextsSize(contextSize)
      addContext(json)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function updateContext(context, botId) {
  fetch(BASE_URL + '/context/bot/' + botId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(context),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Trigger is updated successfully.')
      updateExistedContext(json)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function deleteContext(contextId, contextSize) {
  fetch(BASE_URL + '/context/' + contextId, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Trigger is deleted successfully.')
      // todo: check json response
      deleteOldContexstSize(contextSize)
      removeContext(contextId)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}
