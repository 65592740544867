export const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    width: 480,
    borderRadius: 10,
  },
  btnClose: {
    position: 'absolute',
    right: '24px',
    top: '24px',
    color: '#B0B2C0',
    cursor: 'pointer',
  },
  title: {
    lineHeight: 'normal',
    fontSize: 24,
    color: theme.palette.primary.headerText,
    marginRight: 8,
  },
  searchWrap: {
    margin: '24px 0px 0px',
  },
  header: {
    borderBottom: '1px solid rgba(53, 64, 82, 0.1)',
    padding: '24px 16px',
  },
  body: {
    padding: '24px',
    height: 'calc(80vh - 235px)',
    maxHeight: '408px',
    overflowY: 'scroll',
  },
  footer: {
    borderTop: '1px solid rgba(53, 64, 82, 0.1)',
    padding: '24px',
  },
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleWrap: {
    display: 'flex',
  },
  noAttribute: {
    lineHeight: '22px',
    fontSize: 14,
    color: theme.palette.primary.text,
    height: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
