/* eslint-disable */
import React from 'react'

export const AlertCircleIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9997 4.00016C9.37226 4.00016 3.99967 9.37274 3.99967 16.0002C3.99967 22.6276 9.37226 28.0002 15.9997 28.0002C22.6271 28.0002 27.9997 22.6276 27.9997 16.0002C27.9997 9.37274 22.6271 4.00016 15.9997 4.00016ZM1.33301 16.0002C1.33301 7.89999 7.8995 1.3335 15.9997 1.3335C24.0998 1.3335 30.6663 7.89999 30.6663 16.0002C30.6663 24.1003 24.0998 30.6668 15.9997 30.6668C7.8995 30.6668 1.33301 24.1003 1.33301 16.0002Z" fill="#FF6666"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0003 9.3335C16.7367 9.3335 17.3337 9.93045 17.3337 10.6668V16.0002C17.3337 16.7365 16.7367 17.3335 16.0003 17.3335C15.2639 17.3335 14.667 16.7365 14.667 16.0002V10.6668C14.667 9.93045 15.2639 9.3335 16.0003 9.3335Z" fill="#FF6666"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.667 21.3333C14.667 20.597 15.2639 20 16.0003 20H16.0137C16.75 20 17.347 20.597 17.347 21.3333C17.347 22.0697 16.75 22.6667 16.0137 22.6667H16.0003C15.2639 22.6667 14.667 22.0697 14.667 21.3333Z" fill="#FF6666"/>
    </svg>
  )
}
