import React, {MouseEventHandler} from 'react'
import classes from './styles.module.scss'
import SquareIcon from '../../../uiKit/icons/SquareIcon'
import SquareCheckedIcon from '../../../uiKit/icons/SquareCheckedIcon'

interface Props {
  onChange: MouseEventHandler<HTMLDivElement>
  value: boolean
  title: string
}

const HoverButton: React.FC<Props> = ({ onChange, title, value }) => {
  return (
    <div className={classes.button} onClick={onChange}>
      {value ? <SquareCheckedIcon /> : <SquareIcon />}
      {title}
    </div>
  )
}

export default HoverButton
