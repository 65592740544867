import React, { FC, useState } from 'react'
import { connect } from 'react-redux'

import FunnelsHeader from '../FunnelsHeader'
import FunnelsTable from '../FunnelsTable'
import FunnelModal from '../FunnelModal'
import DeleteModal from '../DeleteModal'
import NoChartData from '../../../../uiKit/NoChartData'

import classes from './styles.module.scss'
import {FunnelType} from '../../../../models/FunnelTypes'

interface Props {
  funnels: FunnelType[]
  fromDate: string
  toDate: string
  scrollToBottom: () => void
}

const FunnelsWrap: FC<Props> = ({ funnels, fromDate, toDate, scrollToBottom }) => {
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedFunnel, setFunnel] = useState<null | FunnelType>(null)

  const handleToggleDeleteModal = (open: boolean, selectedFunnel: null) => {
    setFunnel(selectedFunnel)
    setOpenDeleteModal(open)
  }

  const handleToggleFunnelModal = (open: boolean, selectedFunnel: FunnelType) => {
    setFunnel(selectedFunnel)
    setOpenAddModal(open)
  }

  return (
    <div className={classes.container}>
      <FunnelsHeader onOpen={() => setOpenAddModal(true)} />
      <div className={classes.tableWrap}>
        {funnels?.length ? (
          <FunnelsTable onSelect={handleToggleFunnelModal} onOpenDeleteModal={handleToggleDeleteModal} />
        ) : (
          <NoChartData text="You have not created any funnels yet" />
        )}
      </div>

      {openAddModal && (
        <FunnelModal
          open={openAddModal}
          onClose={() => handleToggleFunnelModal(false, null)}
          selectedFunnel={selectedFunnel}
          fromDate={fromDate}
          toDate={toDate}
          scrollToBottom={scrollToBottom}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          selectedFunnel={selectedFunnel}
          onClose={() => handleToggleDeleteModal(false, null)}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: { funnels: FunnelType[] }) => ({
  funnels: state.funnels,
})

export default connect(mapStateToProps)(FunnelsWrap)
