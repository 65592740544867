import styled from 'styled-components'
import { COMET_GREY, SPUN_PEARL_GREY } from 'constants/colors'

const LastMsgText = styled.p<{ isUnread?: boolean }>`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  margin: 0;
  text-decoration: none;
  color: ${({ isUnread }) => (isUnread ? COMET_GREY : SPUN_PEARL_GREY)};
  font-weight: ${({ isUnread }) => (isUnread ? 'bold' : 'normal')};
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`
export { LastMsgText }
