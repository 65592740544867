import React from 'react'
import Modal from '../Modal'
import LinkTemplate from '../../tabs/flowBuilder/components/messages/LinkTemplate'
import {LinkType} from '../../models/LinkTypes'

interface LinkTemplateModalProps {
  isOpen?: boolean
  setIsOpen?: any
  link?: LinkType
  range?: any
  createLink?: (link: LinkType, isEditing) => void
  setRange?: (range: any) => void
  setCaret?: any
  node?: any
}

export const LinkTemplateModal: React.FC<LinkTemplateModalProps> = ({
  isOpen,
  setIsOpen,
  link,
  createLink,
  setCaret
}) => {
  const handleClose = () => setIsOpen(false)
  const closeWithNoCreation = () => {
    handleClose()
    setCaret()
  }
  return (
    <div>
      {link && (
        <Modal onClose={closeWithNoCreation} open={isOpen} title={link.link.length === 0 ? 'Insert link' : 'Edit link'}>
          <LinkTemplate link={link} onClose={handleClose} createLink={createLink} />
        </Modal>
      )}
    </div>
  )
}
