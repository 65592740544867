import React, { FC, Fragment, useState } from 'react'
import Popover from '@material-ui/core/Popover'

import { CalendarIcon } from '../../../../uiKit/icons/Icons'
import PopoverItem from '../PopoverItem'
import { DIRECTION_ASC, DIRECTION_DESK, SORT_DATE, SORT_SCORE } from '../../constants/commentsFilterOptions'

import classes from './styles.module.scss'

interface Props {
  sortDirection: string
  setSortDirection: (a: string) => void
  sortBy: string
  setSortBy: (a: string) => void
}

const CommentsFilter: FC<Props> = ({ sortDirection, setSortDirection, sortBy, setSortBy }) => {
  const [target, setTarget] = useState<null | HTMLDivElement>(null)
  const [isOpenPopover, setOpenPopover] = useState(false)
  const sortLabel = sortBy === 'score' ? 'Rate' : 'Date'

  return (
    <Fragment>
      <div className={classes.selectButton} onClick={() => setOpenPopover(true)} ref={node => setTarget(node)}>
        <span>
          <CalendarIcon width={16} />
        </span>{' '}
        Sort by: {sortLabel}
      </div>
      <Popover
        classes={{ paper: classes.popover }}
        open={isOpenPopover}
        anchorEl={target}
        onClose={() => setOpenPopover(!isOpenPopover)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <PopoverItem
          label={SORT_DATE.label}
          showCheck={sortBy === SORT_DATE.value}
          onClick={() => setSortBy(SORT_DATE.value)}
        />
        <PopoverItem
          label={SORT_SCORE.label}
          showCheck={sortBy === SORT_SCORE.value}
          onClick={() => setSortBy(SORT_SCORE.value)}
        />

        <div className={classes.divider} />

        <PopoverItem
          label={DIRECTION_ASC.label}
          showCheck={sortDirection === DIRECTION_ASC.value}
          onClick={() => setSortDirection(DIRECTION_ASC.value)}
        />
        <PopoverItem
          label={DIRECTION_DESK.label}
          showCheck={sortDirection === DIRECTION_DESK.value}
          onClick={() => setSortDirection(DIRECTION_DESK.value)}
        />
      </Popover>
    </Fragment>
  )
}

export default CommentsFilter
