import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { styles } from './styles'
import { UploadIcon } from '../icons/UploadIcon'

const ImportButton = ({ classes, onChange }) => {
  const onInputClick = event => {
    event.target.value = ''
  }

  return (
    <div className={classes.input}>
      <input
        type="file"
        id="file"
        accept=".zip"
        onChange={onChange}
        onClick={onInputClick}
      />
      <label htmlFor="file">
        <span>
          <UploadIcon />
        </span>
        Upload new version
      </label>
    </div>
  )
}

ImportButton.propTypes = {
  classes: PropTypes.object,
}
export default withStyles(styles)(ImportButton)
