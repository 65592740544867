/**
 * Created by Admin on 20.02.2018.
 */
import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { clearAtom } from '../flowBuilder/actions/atom'
import { setActiveBot } from './actions/activeBot'
import { getBots } from './api/bots'
import BotPlate from './components/BotPlate'
import CreateNewBot from './components/CreateNewBot'
import Bots from './components/Bots'

const styles = theme => ({})

class Home extends React.Component {
  state = {
    open: true,
  }

  componentWillMount() {
    getBots()
  }

  handleChange(event, name) {
    this.setState({
      [name]: event.target.value,
    })
  }

  switchBot(bot) {
    setActiveBot(bot)
    clearAtom(0)
  }

  render() {
    const { bots } = this.props
    return (
      <Bots>
        <CreateNewBot />
        {bots.map(bot => (
          <BotPlate bot={bot} key={bot.id} switchBot={this.switchBot} />
        ))}
      </Bots>
    )
  }
}

const mapStateToProps = state => ({
  bots: state.bots,
})

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(Home),
)
