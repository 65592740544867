import React from 'react'
import classes from './styles.module.scss'

const Label = ({children}) => {
  return (
    <p className={classes.label}>{children}</p>
  )
}

export default Label
