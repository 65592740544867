import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import {
  getHistoryData,
  getTrainingData,
  trainIntent,
  getAllPagingIntents,
} from '../../api/nlp'
import Loader from '../../../../uiKit/loaders/loader'
import TrainingRequestsView from './Training-requests-view'
import { clearNlpRequests } from '../../actions/nlpRequests'
import { styles } from './Training-styles'
import NoData from '../NoNlpData'
import { LEFT, RIGHT, PAGE_SIZE, NO_DATA_TEXT } from './Training-config'
import TrainingHeaderView from './TrainingHeader-view'
import { getBotLanguages } from '../../../settings/api/settings'

class Training extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      modalTriggerId: 0,
      nlpRequests: null,
      selected: LEFT,
      page: 0,
      selectedLanguageOption: { value: 'all', label: 'All' },
    }
  }

  componentDidMount() {
    this.setLanguagesOptions(this.props.match.params.botId)
    getAllPagingIntents(this.props.match.params.botId, '')
    getTrainingData(
      this.props.match.params.botId,
      this.state.page,
      PAGE_SIZE,
      this.state.selectedLanguageOption.value,
    )
  }

  static getDerivedStateFromProps(nextProps) {
    const { nlpRequests } = nextProps
    return nlpRequests ? { nlpRequests } : null
  }

  componentWillUnmount() {
    clearNlpRequests()
  }

  setLanguagesOptions = botId => {
    getBotLanguages(botId).then(botLanguages => {
      if (botLanguages) {
        const { activeLanguages } = botLanguages

        const languageOptions = activeLanguages
          .filter(language => language)
          .map(language => {
            return {
              label: language.fullName,
              value: language.shortName,
            }
          })

        const defaultSelectedLanguageOption = { label: 'All', value: 'all' }

        languageOptions.unshift(defaultSelectedLanguageOption)

        this.setState({
          languageOptions: languageOptions,
          selectedLanguageOption: defaultSelectedLanguageOption,
        })
      }
    })
  }

  changeTab = tabName => {
    const botId = this.props.match.params.botId
    clearNlpRequests()
    if (tabName === RIGHT) {
      getHistoryData(
        botId,
        0,
        PAGE_SIZE,
        this.state.selectedLanguageOption.value,
      )
    } else {
      getTrainingData(
        botId,
        0,
        PAGE_SIZE,
        this.state.selectedLanguageOption.value,
      )
    }
    this.setState({
      selected: tabName,
      page: 0,
      nlpRequests: null,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page: page }, () => {
      const { botId } = this.props.match.params
      const { selected, selectedLanguageOption } = this.state

      if (selected === LEFT) {
        getTrainingData(botId, page, PAGE_SIZE, selectedLanguageOption.value)
      } else {
        getHistoryData(botId, page, PAGE_SIZE, selectedLanguageOption.value)
      }
    })
  }

  trainRequest = request => {
    if (
      request.triggeredIntentId == null ||
      request.triggeredIntentName == null ||
      request.triggeredIntentName.includes('smalltalk.')
    ) {
      return
    }
    const { botId } = this.props.match.params
    trainIntent(request, botId)
  }

  handleChangeIntent = (value, request) => {
    const newRequests = [...this.state.nlpRequests.data]
    request.triggeredIntentId = value.id
    request.triggeredIntentName = value.value
    request.trained = false
    this.setState({ ...this.state.nlpRequests, data: newRequests })
  }

  handleLanguageChanged = option => {
    this.setState(
      {
        selectedLanguageOption: option,
      },
      () => {
        const { botId } = this.props.match.params
        const { page, selected, selectedLanguageOption } = this.state
        if (selected === LEFT) {
          getTrainingData(botId, page, PAGE_SIZE, selectedLanguageOption.value)
        } else {
          getHistoryData(botId, page, PAGE_SIZE, selectedLanguageOption.value)
        }
      },
    )
  }

  render() {
    const { classes } = this.props
    const { nlpRequests, page, selected } = this.state

    return (
      <div className={classes.tableWrapper}>
        <TrainingHeaderView
          selectedTab={selected}
          changeTab={value => this.changeTab(value)}
          selectedLanguageOption={this.state.selectedLanguageOption}
          languageOptions={this.state.languageOptions}
          handleLanguageChange={this.handleLanguageChanged}
        />

        {!nlpRequests && <Loader />}

        {nlpRequests &&
          (!nlpRequests.totalCount ? (
            <NoData description={NO_DATA_TEXT} />
          ) : (
            <TrainingRequestsView
              nlpRequests={nlpRequests}
              page={page}
              trainRequest={this.trainRequest}
              handleChangeIntent={this.handleChangeIntent}
              handleChangePage={this.handleChangePage}
            />
          ))}
      </div>
    )
  }
}

Training.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  intents: state.intents,
  nlpRequests: state.nlpRequests,
})

export default withStyles(styles)(connect(mapStateToProps)(Training))
