import { RefObject } from 'react'

export const checkAttributeForExistingAndConnection = (
  inputRef: RefObject<HTMLElement>,
  notConnectedAttributes,
  attribute: string,
  caretCurPos: number
):boolean => {
  const attributeWithBrackets = '{{' + attribute + '}}'
  let mistakeFound = false
  inputRef.current.childNodes.forEach((nodeItem, nodeIndex) => {
    if ((nodeItem.nodeType === Node.TEXT_NODE) && nodeItem.nodeValue.includes(attributeWithBrackets)) {
      notConnectedAttributes.forEach(att => {
        if (att === attributeWithBrackets) {
          nodeItem.nodeValue = nodeItem.nodeValue.replace(attributeWithBrackets, '')
          const caretCurPosIndex = caretCurPos - attributeWithBrackets.length
          const range = document.createRange()
          const sel = window.getSelection()
          range.setStart(inputRef.current.childNodes[nodeIndex], caretCurPosIndex)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
          mistakeFound = true
        }
      })
    }
  })
  return mistakeFound
}
