import React, { FC } from 'react'

interface Props {
  color?: string
  size?: string
}

export const CrossIcon: FC<Props> = ({ color, size }) => {
  return (
    <svg width={size || '14'} height={size || '14'} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L1 13" stroke={color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1L13 13" stroke={color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
