export const channelColors: Map<string, string> = new Map<string, string>([
  ['All channels', '#354052'],
  ['Messenger', '#1658F3'],
  ['Telegram', '#FD7F4A'],
  ['Widget', '#F3B116'],
  ['Whatsapp (Amio)', '#4CDBA7'],
  ['Whatsapp (Dialog360)', '#4CDBA7'],
  ['Twilio', '#5A98F7'],
])

export const circleChartColors = ['#1658F3', '#F3B116']

export const color = { r: 22, g: 88, b: 243 }
export const gridItemsCount = 5
