/* eslint-disable */
import React from 'react'

export const SearchIcon = () => {

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16602 2.33268C4.94435 2.33268 2.33268 4.94435 2.33268 8.16602C2.33268 11.3877 4.94435 13.9993 8.16602 13.9993C11.3877 13.9993 13.9993 11.3877 13.9993 8.16602C13.9993 4.94435 11.3877 2.33268 8.16602 2.33268ZM0.666016 8.16602C0.666016 4.02388 4.02388 0.666016 8.16602 0.666016C12.3082 0.666016 15.666 4.02388 15.666 8.16602C15.666 12.3082 12.3082 15.666 8.16602 15.666C4.02388 15.666 0.666016 12.3082 0.666016 8.16602Z"
        fill="#354052"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2851 12.2851C12.6105 11.9597 13.1382 11.9597 13.4636 12.2851L17.0886 15.9101C17.414 16.2355 17.414 16.7632 17.0886 17.0886C16.7632 17.414 16.2355 17.414 15.9101 17.0886L12.2851 13.4636C11.9597 13.1382 11.9597 12.6105 12.2851 12.2851Z"
        fill="#354052"
        fillOpacity="0.7"
      />
    </svg>
  )
}
