import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import Heading from '../../../../uiKit/texts/Heading'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import Urls from '../../components/Urls'
import { SPUN_PEARL_GREY } from '../../../../constants/colors'
import { updateWidget } from '../../api/widget'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import CopySnippet from '../CopySnippet'

import classes from './styles.module.scss'
import {WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'

interface Props {
  match: any
  widgetSettings: WidgetSettingsType
}

const IntegrationTab: React.FC<Props> = ({ match, widgetSettings }) => {
  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])

  const handleUpdateWidgetSettings = (value:string, field:string) => {
    widgetCopy[field] = value
    updateWidget(match.params.botId, widgetCopy)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Heading>Integration</Heading>
      </div>

      <Paragraph style={{ color: SPUN_PEARL_GREY }}>Connect your chatbot to the website you own</Paragraph>

      <div className={classes.body}>
        <Paragraph style={{ margin: '0 0 8px 0' }}>Provide your website URL</Paragraph>
        <Urls
          handleChange={(value:string) => handleUpdateWidgetSettings(value, 'websiteUrls')}
          websiteUrl={widgetSettings?.websiteUrls}
        />
        <CopySnippet />
      </div>
    </div>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default connect(mapStateToProps)(IntegrationTab)
