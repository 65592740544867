import React from 'react'

import classes from './styles.module.scss'

interface Props {
  value: number
  title: string
}

const NamedCounter: React.FC<Props> = ({ value, title }) => {
  return (
    <div className={classes.counter}>
      <p className={classes.value}>{value}</p>
      <p className={classes.title}>{title}</p>
    </div>
  )
}

export default NamedCounter
