import React from 'react'
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Input from '../../../../uiKit/inputs/Input'
import { alertError, ifStringEmpty } from '../../../../api'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'

const styles = theme => ({
  warning: {
    color: '#FF624C',
    margin: 0,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
})

class LinkTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      link: {
        link: '',
        text: '',
        messengerExtensions: false,
      },
      initialLink: JSON.parse(JSON.stringify(props.link)),
      urlError: false,
    }
  }

  handleChange = (name, value) => {
    const { link } = this.state
    link[name] = value
    this.updateLink(link)
  }

  updateLink = link => {
    this.setState({ link })
  }

  componentDidMount() {
    const link = Object.assign({}, this.props.link)
    const isSting = typeof link.messengerExtensions === 'string'
    link.messengerExtensions = isSting ? JSON.parse(link.messengerExtensions) : link.messengerExtensions
    this.updateLink(link)
  }

  urlValidation(url) {
    return !/^(ftp|http|https):\/\/[^ "]+$/.test(url)
  }

  submitLink = () => {
    const { onClose, createLink } = this.props
    const { link, initialLink } = this.state

    if (ifStringEmpty(link.link) || ifStringEmpty(link.text)) {
      alertError("name and url fields shouldn't be empty")
      throw "name and url fields shouldn't be empty"
    }
    if (this.urlValidation(link.link)) {
      return alertError('please enter valid Url')
    }
    createLink(link, ((initialLink.link.length) !== 0))
    onClose()
  }

  render() {
    const { classes } = this.props
    const { initialLink, urlError } = this.state
    const { text, link, messengerExtensions } = this.state.link
    return (
      <div>
        <Input
          value={text}
          title="Name"
          placeholder="Enter new link name"
          onChange={e => this.handleChange('text', e.target.value)}
        />
        <Input
          value={link}
          title="Url"
          placeholder="Paste URL here"
          onChange={e => this.handleChange('link', e.target.value)}
        />
        {urlError && <p className="warning">Please enter valid URL</p>}
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={() => this.handleChange('messengerExtensions', !messengerExtensions)}
              value={String(messengerExtensions)}
              checked={messengerExtensions}
              color="primary"
            />
          }
          label="Open in Widget"
        />
        <div className={classes.btnContainer}>
          <SubmitButton
            onClick={this.submitLink}
            size="lg"
            title={initialLink && initialLink.link.length === 0 ? 'Create' : 'Edit'}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(LinkTemplate))
