import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { Popover } from '@material-ui/core'

import InputContent from '../../../../uiKit/inputs/InputContent'

import classes from './styles.module.scss'

interface Props {
  value: string
  onChange: (a: string) => void
}

const ColorSelector: React.FC<Props> = ({ value, onChange }) => {
  const [color, setColor] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'color-popover' : undefined

  useEffect(() => {
    setColor(value)
  }, [value])

  const handleClose = () => {
    setAnchorEl(null)
    if (value !== color) onChange(color)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <InputContent title="Choose widget color" onClick={handleClick}>
        <div className={classes.input} aria-describedby={id}>
          <p className={classes.value}>{value}</p>
          <div style={{ background: value }} className={classes.color} />
        </div>
      </InputContent>

      <Popover
        open={open}
        onClose={handleClose}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <SketchPicker color={color} onChange={(color: any) => setColor(color.hex)} />
      </Popover>
    </>
  )
}

export default ColorSelector
