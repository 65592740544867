import { backEndProcessing } from './backEndProcessing/backEndProcessing'
import { RefObject } from 'react'
import {AttributeItemType} from '../../../../models/AttributeTypes'

const insertArrayOfNodes = (
  wrapEl: HTMLElement,
  insertionWrapperEL: HTMLElement,
  nextElement: any,
  setNode: (a: any) => void,
) => {
  const length = insertionWrapperEL.childNodes.length
  const insertionWrapperChild = insertionWrapperEL.childNodes

  for (let i = 0; i < length; i++) {
    wrapEl.insertBefore(insertionWrapperChild[0], nextElement)
    if (i === insertionWrapperChild.length - 1) {
      setNode(insertionWrapperChild[i])
    }
  }
}

export const handleCtrlV = (
  event: any,
  attributes: AttributeItemType[],
  inputRef: RefObject<HTMLElement>,
  caretNodePos: number,
  setValue: (a: string) => void,
  setNode: (a: any) => void,
  botId: number,
  isMenu: boolean,
) => { //NOSONAR
  const charCode = String.fromCharCode(event.which).toLowerCase()

  if (window.getSelection().rangeCount > 0) {
    const selectedText = window.getSelection().toString()
    const caretP = window.getSelection()?.getRangeAt(0)
    const anchor = window.getSelection().anchorNode
    if (((event.ctrlKey || event.metaKey) && charCode === 'v') || isMenu) {
      navigator.clipboard.readText().then(text => {
        text = backEndProcessing(text, attributes, botId)
        const wrapper = document.createElement('span')
        wrapper.innerHTML = text
        const lastEl = wrapper?.childNodes[wrapper.childNodes?.length - 1]


        if (selectedText) {
          caretP.deleteContents()
          const length = wrapper.childNodes.length
          for (let i = 0; i < length; i++) {
            caretP.insertNode(wrapper.childNodes[length - i - 1])
            if (i === length - 1) {
              setNode(wrapper.childNodes[i])
            }
          }
        } else if (anchor === inputRef.current && caretP.startOffset) {
          inputRef.current.childNodes.forEach((nodeItem, index) => {
            if (index === caretP.startOffset - 1) {
              insertArrayOfNodes(inputRef.current, wrapper, nodeItem.nextSibling, setNode)
            }
            return index === caretNodePos - 1
          })
        } else if (anchor?.nodeValue) {
          const text1 = anchor.nodeValue?.substr(0, caretP.startOffset) || ''
          const text2 = anchor.nodeValue?.substr(caretP.startOffset) || ''
          const text1Node = document.createTextNode(text1)
          const text2Node = document.createTextNode(text2)
          inputRef.current?.replaceChild(text1Node, anchor)
          const next = text1Node.nextSibling
          insertArrayOfNodes(inputRef.current, wrapper, next, setNode)
          inputRef.current?.insertBefore(text2Node, lastEl?.nextSibling)
        } else if (!caretP || (!caretP.startOffset && !caretP.endOffset)) {
          insertArrayOfNodes(inputRef.current, wrapper, inputRef.current.childNodes[0], setNode)
        }
        setValue(inputRef?.current.innerHTML)
        setNode(lastEl)
      })
      event.preventDefault()
    }
  }
}
