import { store } from '../../../index'

export const SET_BOT_WIDGET = 'SET_BOT_WIDGET'
export const CLEAR_WIDGET_SETTINGS = 'CLEAR_WIDGET_SETTINGS'

export const setBotWidget = widgetSettings => {
  store.dispatch({
    type: SET_BOT_WIDGET,
    widgetSettings,
  })
}

export const clearWidgetSettings = () => {
  store.dispatch({
    type: CLEAR_WIDGET_SETTINGS,
  })
}
