import React from 'react'
import './Loader-small.css'

const LoaderSmall = props => {
  return props.showLoader ? (
    <div className="Loader-small">
      <img src="/images/platform/loader.svg" alt="loader" />
    </div>
  ) : (
    <div />
  )
}

export default LoaderSmall
