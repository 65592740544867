import React, { useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { customStyles, styles } from './styles'
import Select, { components } from 'react-select'
import { LanguageArrowDown } from '../../../../uiKit/icons/LanguageArrowDown'
import { LanguageArrowUp } from '../../../../uiKit/icons/LanguageArrowUp'

const Index = props => {
  const { onChange, value, classes } = props
  const [isOpen, setIsOpen] = useState(false)
  const options = useMemo(
    () => [
      {
        value: 'ALL',
        label: 'All conversations',
      },
      {
        value: 'COMPLETED_NEXT_STEP',
        label: 'Completed next step',
      },
      {
        value: 'DROP_OFF',
        label: 'Dropped off this step',
      },
    ],
    [],
  )

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        {props.children}
        <span className={classes.arrow}>{isOpen ? <LanguageArrowUp /> : <LanguageArrowDown />}</span>
      </components.SingleValue>
    )
  }

  return (
    <Select
      options={options}
      value={value}
      styles={customStyles}
      isSearchable={false}
      onChange={onChange}
      components={{ SingleValue }}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
    />
  )
}

Index.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Index)
