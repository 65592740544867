import React, { FC, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'

import ModalTemplate from '../../../../uiKit/ModalTemplate'
import Input from '../../../../uiKit/inputs/Input'
import Divider from '../../../../uiKit/Divider'
import { TrashIcon } from '../../../../uiKit/icons/Icons'
import { setErrors } from '../../../../helpers/setErrors/setErrors'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AtomMultiSelect from '../../../../uiKit/AtomMultiSelect/AtomMultiSelect'
import FunnelModalFooter from '../FunnelModalFooter'
import { funnelData, newStep } from '../../constants/elements'
import { addFunnel, updateFunnel } from '../../api/funnels'

import classes from './styles.module.scss'
import { useStateCallback } from '../../../../hooks/useStateCallback'
import { FunnelType } from '../../../../models/FunnelTypes'
import { OptionItemType } from '../../../../models/MessageTypes'

interface Props {
  open: boolean
  onClose: () => void
  botId: number
  fromDate: string
  toDate: string
  selectedFunnel: FunnelType
  funnels: FunnelType[]
  scrollToBottom: () => void
}

const FunnelModal: FC<Props> = ({
  open,
  onClose,
  botId,
  selectedFunnel,
  funnels,
  fromDate,
  toDate,
  scrollToBottom,
}) => {//NOSONAR
  const [errorsName, setErrorsName] = useState<string[]>([])
  const [errorsSteps, setErrorsSteps] = useState([])
  const [funnel, setFunnel] = useStateCallback(selectedFunnel || funnelData)
  const containerEndRef = useRef(null)

  const funnelCopy = useMemo(() => deepCopyFunction(funnel), [funnel])
  const errorsStepsCopy = useMemo(() => deepCopyFunction(errorsSteps), [errorsSteps])

  const handleUpdateName = (value: string) => {
    validateName(value)
    funnelCopy.name = value
    setFunnel(funnelCopy)
  }

  const handleUpdateStep = (value: OptionItemType[], index: number) => {
    validateSteps(value, index)
    funnelCopy.steps[index].atomIds = value.map(({ value }) => value)
    setFunnel(funnelCopy)
  }

  const handleAddStep = () => {
    funnelCopy.steps.push(deepCopyFunction(newStep))
    setFunnel(funnelCopy, handleScrollToBottom)
  }

  const handleScrollToBottom = () => {
    if (containerEndRef.current) {
      containerEndRef.current.scrollIntoView()
    }
  }

  const handleDeleteStep = (index: number) => {
    funnelCopy.steps.splice(index, 1)
    errorsStepsCopy.splice(index, 1)

    setErrorsSteps(errorsStepsCopy)
    setFunnel(funnelCopy)
  }

  const handleSave = () => {
    const error = validateFunnel()

    if (!error) {
      funnelCopy.steps = funnel.steps.map((step, index) => {
        step.stepNumber = index + 1
        return step
      })
      funnelCopy.botId = botId
      if (selectedFunnel && !selectedFunnel.isCopy) {
        updateFunnel({ botId, funnel: funnelCopy, from: fromDate, to: toDate })
      } else {
        addFunnel({ botId, funnel: funnelCopy, from: fromDate, to: toDate }).then(() => scrollToBottom())
      }
      onClose()
    }
  }

  const validateName = (value: string) => {
    // @ts-ignore
    const usedNames = funnels?.filter(({ id }) => id !== funnel?.id)?.map(({ name }) => name)
    const errors = setErrors(value, 50, usedNames)
    setErrorsName(errors)

    return !errors.length
  }

  const validateSteps = (value: OptionItemType[] | number[], index: number) => {
    errorsStepsCopy[index] = setErrors(value)
    setErrorsSteps(errorsStepsCopy)

    return !errorsStepsCopy.filter((arr: string[]) => arr?.length)?.length
  }

  const validateFunnel = () => {
    const nameError = validateName(funnel.name)
    const stepsError = funnel.steps.reduce((total, { atomIds }, index) => validateSteps(atomIds, index), false)

    return !nameError || !stepsError
  }

  return (
    <ModalTemplate
      open={open}
      onClose={onClose}
      onSave={handleSave}
      title={selectedFunnel?.name && !selectedFunnel?.isCopy ? `Edit ${selectedFunnel.name}` : 'Create funnel'}
      customFooter={<FunnelModalFooter onClick={handleAddStep} showButton={funnel.steps.length < 10} />}>
      <div className={classes.container}>
        <Input
          title="Funnel name"
          placeholder="Enter funnel name"
          value={funnel.name || ''}
          onChange={(event: any) => handleUpdateName(event.target.value)}
          error={!!errorsName.length}
          inputTitleStyles={{ margin: '0px 0px 8px' }}
          errorMessage={errorsName[0]}
        />

        <Divider margin={24} />

        {funnel.steps.map((step, index) => (
          <div className={classes.stepWrap} key={step.stepNumber}>
            <AtomMultiSelect
              onChange={(value: OptionItemType[]) => handleUpdateStep(value, index)}
              values={step.atomIds}
              title={`Step ${index + 1}`}
              error={!!errorsSteps[index]?.length}
              errorMessage={!!errorsSteps[index]?.length && errorsSteps[index][0]}
            />
            {index > 1 && (
              <div className={classes.iconWrap} onClick={() => handleDeleteStep(index)}>
                <TrashIcon />
              </div>
            )}
          </div>
        ))}
        <div ref={containerEndRef} />
      </div>
    </ModalTemplate>
  )
}

const mapStateToProps = (state: { activeBot: { id: any }; funnels: FunnelType[] }) => ({
  botId: state.activeBot?.id,
  funnels: state.funnels,
})

export default connect(mapStateToProps)(FunnelModal)
