/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateGreetingText } from '../../../../home/api/bots'
import withStyles from '@material-ui/core/styles/withStyles'
import GreetingText from './GreetingText'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import Paragraph from '../../../../../uiKit/texts/Paragraph'
import { setActiveBot } from '../../../../home/actions/activeBot'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EBEBEB',
    paddingTop: 24,
    justifyContent: 'space-between',
  },
  content: {},
  heading: {
    fontSize: 16,
    color: theme.palette.text.header,
    marginBottom: 8,
    fontFamily: 'Lato, sans-serif',
    lineHeight: 1,
  },
  paragraph: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 14,
    color: theme.palette.primary.text,
    lineHeight: '18px',
  },
  block: {
    borderBottom: '1px solid #EBEBEF',
    paddingBottom: 32,
  },
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
    color: '#1658F3',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    width: '400px',
  },
  btn: {
    marginTop: 16,
    cursor: 'pointer',
    border: '1px solid #5A98F7',
    boxSizing: 'border-box',
    borderRadius: 10,
    color: '#5A98F7',
    padding: '8px 16px',
    width: 'max-content',
  },
  btnDelete: {
    marginTop: 16,
    cursor: 'pointer',
    border: '1px solid rgba(97, 101, 129, 0.5)',
    boxSizing: 'border-box',
    borderRadius: 10,
    color: 'rgba(97, 101, 129, 0.5)',
    padding: '8px 16px',
    width: 'max-content',
  },
  modal: {
    padding: '40px 160px',
    margin: '15% auto' /* 15% from the top and centered */,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
    borderRadius: 10,
    overflowY: 'auto',
    backgroundColor: 'white',
    width: 640,
  },
  img: {
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
    top: 50,
  },
  imgHolder: {
    flexBasis: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

class Greeting extends React.Component {
  state = {
    changed: false,
  }
  handleOpen = () => this.setState({ open: true })
  handleClose = () => this.setState({ open: false })
  handleChangeGreetingText = value => {
    const bot = JSON.parse(JSON.stringify(this.state.bot))
    bot.greetingText = value
    this.setState({ bot, changed: true })
  }
  handleUpdate = () => {
    const { bot } = this.state
    const { activeBot } = this.props

    updateGreetingText(bot.id, bot.greetingText, () => {
      this.setState({ changed: false })
      activeBot.greetingText = bot.greetingText
      setActiveBot(bot)
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.changed)
      return {
        bot: nextProps.activeBot,
      }
    return null
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div>
          <div style={{ maxWidth: 400 }}>
            <p className={classes.heading}>Set the Greeting Text</p>
            <Paragraph>
              The greeting text of your bot's Messenger profile allows you to
              specify the message people will see on the welcome screen of your
              bot. You can personalize the greeting text using the person's
              name. You can use the following template strings:
            </Paragraph>
          </div>
          <GreetingText
            text={this.state.bot.greetingText}
            onChange={value => {
              this.handleChangeGreetingText(value)
            }}
          />
          <SubmitButton
            title="Save"
            styles={{ margin: '36px 0 0' }}
            onClick={this.handleUpdate}
          />
        </div>
        <div className={classes.imgHolder}>
          <img
            src="/images/platform/integrations-bg.svg"
            alt=""
            className={classes.img}
          />
          {/*<img src={src} alt="" className={classes.img}/>*/}
        </div>
      </div>
    )
  }
}

Greeting.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(Greeting),
)
