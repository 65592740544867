/**
 * Created by Admin on 13.04.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Params from '../../../../../uiKit/inputs/Params'

const styles = theme => ({
  paramList: {
    display: 'flex',
    margin: '8px 0 16px',
  },
  param: {
    border: '1px solid #EBEBEF',
    boxSizing: 'border-box',
    borderRadius: 10,
    padding: '8px 14px',
    marginRight: 8,
    fontSize: 14,
  },
})

const params = [
  {
    id: -1,
    name: 'user_first_name',
  },
  {
    id: -2,
    name: 'user_last_name',
  },
  {
    id: -3,
    name: 'user_full_name',
  },
]

const GreetingText = ({ classes, onChange, text, activeBot }) => (
  <div className={classes.container}>
    <div className={classes.paramList}>
      {params.map(param => (
        <div className={classes.param} key={param.id}>
          {'{{' + param.name + '}}'}
        </div>
      ))}
    </div>
    <Params
      onChange={value => onChange(value)}
      value={text}
      placeholder="Hello, this is a basic example of greeting text of your bot which will be displayed here."
      ifGreeting={true}
      bot={activeBot}
      params={params}
    />
  </div>
)

GreetingText.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(GreetingText),
  ),
)
