export const styles = () => ({
  container: {
    display: 'flex',
    transform: 'translateZ(0)',
    '-webkit-text-size-adjust': 'none',
    width: '100%',
    position: 'relative',
    '&:hover > div': {
      visibility: 'visible',
    },
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1,
    border: '1px solid #e5e5e5',
    backgroundColor: '#fff',
    overflow: 'auto',
    pointerEvents: 'none',
    transition: 'transform 1s',
    width: '100%',
    lineHeight: '20px',
    fontSize: '14px',
    borderRadius: 10,
    height: 120,
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  highlights: {
    padding: 10,
    lineHeight: '20px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflow: 'scroll',
    '&>span': {
      display: 'inline',
    },
  },
  highlightsInput: {
    padding: 10,
    lineHeight: '20px',
    wordWrap: 'break-word',
    resize: 'none',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&>span': {
      display: 'inline',
    },
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  textarea: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    margin: 0,
    border: '1px solid #e5e5e5',
    color: 'transparent',
    backgroundColor: 'transparent',
    overflow: 'auto',
    resize: 'none',
    transition: 'transform 1s',
    outline: 'none',
    padding: '10px 12px 10px 10px',
    lineHeight: '20px',
    caretColor: '#000000',
    fontFamily: "'Lato', sans-serif",
    wordWrap: 'break-word',
    height: 120,
  },
  input: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    margin: 0,
    border: '1px solid #D7D8DF',
    color: 'transparent',
    backgroundColor: 'transparent',
    overflow: 'auto',
    resize: 'none',
    transition: 'transform 1s',
    outline: 'none',
    padding: 10,
    lineHeight: '20px',
    caretColor: '#000000',
    fontFamily: "'Lato', sans-serif",
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar-thumb': {
      background: 'transparent',
    },
  },
  icon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    backgroundColor: 'rgba(53, 64, 82, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  iconsWrap: {
    position: 'absolute',
    visibility: 'hidden',
    zIndex: 2,
    bottom: 4,
    right: 4,
    display: 'flex',
    gap: '2px',
  },
})
