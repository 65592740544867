export const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    width: 600,
    borderRadius: 10,
    padding: 40,
  },
  titleWrap: {
    display: 'flex',
  },
  title: {
    lineHeight: 'normal',
    fontSize: 24,
    color: theme.palette.primary.headerText,
    margin: '0px 8px 32px 0px',
  },
  btnClose: {
    position: 'absolute',
    right: '24px',
    top: '24px',
    color: '#B0B2C0',
    cursor: 'pointer',
  },
  body: {
    display: 'flex',
    gap: '32px',
  },
  section: {
    width: '50%',
    display: 'block',
  },
})
