/**
 * Created by Admin on 15.02.2018.
 */
import React from 'react'
import MessageQuickReplies from './MessageQuickReplies'
import FileDropzone from '../../../../uiKit/inputs/FileDropzone'

export default class Attachment extends React.Component {
  state = {}

  handleChange(fileUrl) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.url = fileUrl
    this.props.updateMessage(newMessage)
  }

  render() {
    const { rtl } = this.props
    return (
      <div style={{ width: '100%' }}>
        <div className="message">
          <FileDropzone
            value={this.props.message.attachment.payload.url}
            onChange={fileUrl => this.handleChange(fileUrl)}
            title="Replace image"
          />
          {this.props.onDelete && (
            <div className="dlt-msg" onClick={() => this.props.onDelete()}>
              <img src="/images/platform/delete.svg" alt="delete" />
            </div>
          )}
        </div>
        <MessageQuickReplies
          message={this.props.message}
          rtl={rtl}
          updateMessage={message => this.props.updateMessage(message)}
        />
      </div>
    )
  }
}
