import React, {useMemo, useState} from 'react'
import {PieChart} from 'react-chartkick'
import {connect} from 'react-redux'

import NoChartData from '../../../../uiKit/NoChartData'

import {noDataText} from '../../constants/chartsTexts'
import {channelColors} from '../../constants/channelColors'
import {getPercentage} from '../../../../helpers/getPercentage'

import classes from './styles.module.scss'
import {sortArrayOfArraysByIndex} from '../../../../helpers/sortArrayOfArraysByIndex'
import {usersChartDataType} from '../../../../models/DashboardTypes'

interface Props {
  usersStatistics: usersChartDataType
}

const ChannelsCircleChart: React.FC<Props> = ({ usersStatistics: chartData }) => {
  const [otherChannels, setOtherChannels] = useState([])

  const getOrderedData = (data: Map<string, number>) => {
    const result = []
    sortArrayOfArraysByIndex(Object.entries(data), 1, 'desc')
      .forEach(e => result[e[0]] = e[1])
    return result
  }

  const getChartData = () => {
    const pieChartData = getOrderedData(chartData.usersBetweenDates)

    if (pieChartData.length > 4) {
      return getChartDataWithOther(pieChartData)
    }

    return Object.entries(pieChartData)
  }

  const getChartDataWithOther = formattedPieChartData => {
    const mainPlatforms = formattedPieChartData.slice(0, 4)
    const otherPlatforms = formattedPieChartData.slice(4)
    const otherPlatformsCount = otherPlatforms.reduce((a, [, userCount]) => a + userCount, 0)

    setOtherChannels(otherPlatforms)
    return [...mainPlatforms, ['Other', otherPlatformsCount]]
  }

  const getColorsForLineChart = data => {
    return data.map(value => channelColors.get(value[0]))
  }

  const getLibraryOptions = () => {
    return {
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex]
            const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue)
            const currentValue = dataset.data[tooltipItem.index]
            const percentage = getPercentage(currentValue, total)

            if (data.labels[tooltipItem.index] !== 'Others') {
              return `${data.labels[tooltipItem.index]}: ${percentage}% (${currentValue})`
            }

            return otherChannels
              .map(channel => `${channel[0]}: ${getPercentage(channel[1], total)}% (${channel[1]})`)
              .join('\n')
          },
        },
      },
    }
  }

  const data = useMemo(() => getChartData(), [chartData])
  const colors = useMemo(() => getColorsForLineChart(data), [data])
  const libraryOptions = useMemo(() => getLibraryOptions(), [otherChannels])

  return (
    <div className={classes.container}>
      {data.length ? (
        <PieChart data={data} legend={'bottom'} colors={colors} library={libraryOptions} />
      ) : (
        <NoChartData text={noDataText} />
      )}
    </div>
  )
}

const mapStateToProps = (state: { usersStatistics: usersChartDataType; supportStatistics }) => ({
  usersStatistics: state.usersStatistics,
})

export default connect(mapStateToProps)(ChannelsCircleChart)
