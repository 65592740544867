import styled from 'styled-components'
import { PERMISSION_RED, SPUN_PEARL_GREY } from 'constants/colors'
import SubmitButton from 'uiKit/buttons/SubmitButton'

const DialogflowIntegration = styled.div`
  label {
    margin-bottom: 0;
  }
`

const Label = styled.p`
  font-size: 12px;
  color: ${SPUN_PEARL_GREY};
  margin: 4px 0 12px;
`

const Error = styled.p`
  color: ${PERMISSION_RED};
  font-size: 12px;
  margin: 4px 0 12px;
`

const SubmitButtonStyled = styled(SubmitButton)<{ isMarginBottom?: boolean }>`
  width: fit-content;
  margin-bottom: ${({ isMarginBottom }) => (isMarginBottom ? 32 : 0)}px;
`

export { DialogflowIntegration, Label, Error, SubmitButtonStyled }
