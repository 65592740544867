import { createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL } from '../../../configs'

async function updateWhatsApp360DialogConfigs(botId, data) {
  const response = await fetch(`${BASE_URL}/bot/${botId}/dialog360WhatsAppConfig`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(data),
  })

  return await logoutOnRequestOrResponseJson(response)
}
export { updateWhatsApp360DialogConfigs }
