import React, { FC, useMemo } from 'react'
import moment from 'moment'

import PopoverSupport from '../PopoverSupport'

import classes from './styles.module.scss'
import {FeedbackType} from '../../../../models/AnalyticsType'

interface Props {
  data: FeedbackType
}

const Comment: FC<Props> = ({data}) => {
  const date = useMemo(() => moment(data.date).format('MMM DD, YYYY'), [data])

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.nameContainer}>
          <p className={classes.nameWrap}>
            {data.firstName} {data.lastName} <span>{date}</span>
          </p>
          <PopoverSupport userId={data.userId} messageId={data.messageId} />
        </div>
        <div className={classes.score}>{'★'.repeat(data.score)}</div>
        <div className={classes.comment}>{data.comment}</div>
      </div>
    </div>
  )
}

export default Comment
