export const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
  },
  iconWrap: {
    cursor: 'pointer',
    display: 'flex',
  },
  addButton: {
    border: '1px solid rgba(22, 88, 243, 1)',
    height: 42,
    minWidth: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
})
