import { smallTalkIntent } from './constants'

export const isValueSmallTalk = (value: string) => value?.includes(smallTalkIntent)

export const createIntentObject = (label: string, value: string, id: number) => ({ label, value, id })

export const useSelectIntents = intents => {
  const getIntentLabel = intent => `${intent.faq ? 'FAQ' : ''}${intent.name}`

  return intents.map(intent => createIntentObject(getIntentLabel(intent), intent.name, intent.id))
}
