export const SIZE = 10

export const saveSortDirection = sortDirection => {
  localStorage.setItem('sortDirection', sortDirection)
}

export const saveSortBy = sortBy => {
  localStorage.setItem('sortBy', sortBy)
}

export const getSortingParams = () => {
  return {
    sortDirectionSaved: localStorage.getItem('sortDirection'),
    sortBySaved: localStorage.getItem('sortBy'),
  }
}
