/* eslint-disable */
import React from 'react'

const SentStatusIcon = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 1.02851C12.0651 1.28886 12.0651 1.71097 11.8047 1.97132L4.4714 9.30466C4.21106 9.56501 3.78894 9.56501 3.5286 9.30466L0.195262 5.97132C-0.0650874 5.71097 -0.0650874 5.28886 0.195262 5.02851C0.455612 4.76816 0.877722 4.76816 1.13807 5.02851L4 7.89044L10.8619 1.02851C11.1223 0.768165 11.5444 0.768165 11.8047 1.02851Z"
        fill="#B0B2C0"
      />
    </svg>
  )
}

const DeliveredStatusIcon = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 1.02851C12.0651 1.28886 12.0651 1.71097 11.8047 1.97132L4.4714 9.30466C4.21106 9.56501 3.78894 9.56501 3.5286 9.30466L0.195262 5.97132C-0.0650874 5.71097 -0.0650874 5.28886 0.195262 5.02851C0.455612 4.76816 0.877722 4.76816 1.13807 5.02851L4 7.89044L10.8619 1.02851C11.1223 0.768165 11.5444 0.768165 11.8047 1.02851Z"
        fill="#1658F3"
      />
    </svg>
  )
}

const ReadStatusIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_92_2072)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8047 4.02851C12.0651 4.28886 12.0651 4.71097 11.8047 4.97132L4.4714 12.3047C4.21106 12.565 3.78894 12.565 3.5286 12.3047L0.195262 8.97132C-0.0650874 8.71097 -0.0650874 8.28886 0.195262 8.02851C0.455612 7.76816 0.877722 7.76816 1.13807 8.02851L4 10.8904L10.8619 4.02851C11.1223 3.76816 11.5444 3.76816 11.8047 4.02851ZM15.8047 4.02851C16.0651 4.28886 16.0651 4.71097 15.8047 4.97132L8.47141 12.3047C8.21106 12.565 7.78895 12.565 7.5286 12.3047L6.76997 11.546C6.50962 11.2857 6.50962 10.8636 6.76997 10.6032C7.03031 10.3429 7.45242 10.3429 7.71277 10.6032L8 10.8904L14.8619 4.02851C15.1223 3.76816 15.5444 3.76816 15.8047 4.02851Z"
          fill="#1658F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_92_2072">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

const FailedStatusIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99996 2.50008C4.68625 2.50008 1.99996 5.18637 1.99996 8.50008C1.99996 11.8138 4.68625 14.5001 7.99996 14.5001C11.3137 14.5001 14 11.8138 14 8.50008C14 5.18637 11.3137 2.50008 7.99996 2.50008ZM0.666626 8.50008C0.666626 4.44999 3.94987 1.16675 7.99996 1.16675C12.05 1.16675 15.3333 4.44999 15.3333 8.50008C15.3333 12.5502 12.05 15.8334 7.99996 15.8334C3.94987 15.8334 0.666626 12.5502 0.666626 8.50008Z"
        fill="#DE4244"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 5.16675C8.36823 5.16675 8.66671 5.46522 8.66671 5.83341V8.50008C8.66671 8.86827 8.36823 9.16675 8.00004 9.16675C7.63185 9.16675 7.33337 8.86827 7.33337 8.50008V5.83341C7.33337 5.46522 7.63185 5.16675 8.00004 5.16675Z"
        fill="#DE4244"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33337 11.1667C7.33337 10.7985 7.63185 10.5 8.00004 10.5H8.00671C8.3749 10.5 8.67337 10.7985 8.67337 11.1667C8.67337 11.5349 8.3749 11.8333 8.00671 11.8333H8.00004C7.63185 11.8333 7.33337 11.5349 7.33337 11.1667Z"
        fill="#DE4244"
      />
    </svg>
  )
}

export { SentStatusIcon, DeliveredStatusIcon, ReadStatusIcon, FailedStatusIcon }
