export const funnelData = {
  name: '',
  steps: [
    {
      atomIds: [],
      stepNumber: 1,
    },
    {
      atomIds: [],
      stepNumber: 2,
    },
  ],
}

export const newStep = {
  atomIds: [],
}
