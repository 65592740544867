import { connect } from 'react-redux'
import React, { FC, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Table from '../../../../uiKit/table/Table'
import TableRow from '../../../../uiKit/table/TableRow'
import TableCell from '../../../../uiKit/table/TableCell'
import { ThreeIcon } from '../../../../uiKit/icons/ThreeIcon'
import { EditIcon } from '../../../../uiKit/icons/EditIcon'
import { DeleteIcon } from '../../../../uiKit/icons/DeleteIcon'
import { CopyIcon } from '../../../../uiKit/icons/Icons'
import Popover from '../../../../uiKit/Popover'
import { titles } from '../../constants/tableTitles'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { HOME_PATH } from '../../../../configs'

import classes from './styles.module.scss'
import { FunnelType } from '../../../../models/FunnelTypes'

interface Props extends RouteComponentProps {
  funnels: FunnelType[]
  onSelect: (a: boolean, b: FunnelType) => void
  onOpenDeleteModal: (a: boolean, b: FunnelType) => void
  history: any
  match: any
}

const FunnelsTable: FC<Props> = ({ funnels, onSelect, onOpenDeleteModal, history, match }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openedPopoverId, setOpenedPopoverId] = useState<null | number>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleOpenPopover = (event: any, index: number) => {
    setOpenedPopoverId(index)
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const handleEdit = (funnel: FunnelType) => {
    onSelect(true, funnel)
    handleClosePopover()
  }

  const handleCopy = (funnel: FunnelType) => {
    const copyFunnel = deepCopyFunction(funnel)
    copyFunnel.isCopy = true
    copyFunnel.name = `Copy of ${copyFunnel.name}`
    copyFunnel.id = null
    onSelect(true, copyFunnel)
    handleClosePopover()
  }

  const handleDelete = (funnel: FunnelType) => {
    onOpenDeleteModal(true, funnel)
    handleClosePopover()
  }

  const handleDetails = (funnel: FunnelType) => {
    history.push(`${HOME_PATH}/bot/${match.params.botId}/analytics/${funnel.id}`)
  }

  return (
    <Table titles={titles}>
      {funnels?.map((funnel: FunnelType, index: number) => (
        <TableRow key={funnel.id} index={0} style={{ height: 56 }}>
          <TableCell styles={{ maxWidth: 270 }}>{funnel.name}</TableCell>
          <TableCell styles={{ maxWidth: 120 }}>{funnel.steps?.length}</TableCell>
          <TableCell styles={{ maxWidth: 130 }}>{funnel.totalConversations}</TableCell>
          <TableCell styles={{ maxWidth: 120 }}>{funnel.completionPercentage} %</TableCell>
          <TableCell styles={{ maxWidth: 120 }}>
            <div className={classes.link} onClick={() => handleDetails(funnel)}>
              Show more
            </div>
          </TableCell>
          <TableCell styles={{ maxWidth: 20 }}>
            <div className={classes.button} onClick={event => handleOpenPopover(event, index)}>
              <ThreeIcon variant="contained" color="primary" />
            </div>
          </TableCell>

          <Popover id={id} open={openedPopoverId === index} anchorEl={anchorEl} onClose={handleClosePopover}>
            <div>
              <div className={classes.editButton} onClick={() => handleEdit(funnel)}>
                <EditIcon /> Edit
              </div>
              <div className={classes.editButton} onClick={() => handleCopy(funnel)}>
                <CopyIcon color={'#354052'} width={16} /> Copy
              </div>
              <div className={classes.deleteButton} onClick={() => handleDelete(funnel)}>
                <DeleteIcon /> Delete
              </div>
            </div>
          </Popover>
        </TableRow>
      ))}
    </Table>
  )
}

const mapStateToProps = (state: { funnels: FunnelType[] }) => ({
  funnels: state.funnels,
})

export default connect(mapStateToProps)(withRouter(FunnelsTable))
