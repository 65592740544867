import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { styles } from './Training-styles'
import IntentsSelect from '../IntentSelect/index'
import TrainButton from './TrainButton'
import { DoneIcon } from '../../../../uiKit/icons/Icons'
import { getNestedProps } from '../../../../helpers/getNestedProps'

function TrainingRequestView(props) {
  const { classes, request, handleChangeIntent, trainRequest } = props
  return (
    <div className={classes.mainSelected} key={request.id}>
      <div className={classes.selectItem}>
        <p className={classes.desc}>User Says</p>
        <p
          className={classes.userSay}
          style={{
            textAlign: getNestedProps(request, 'language.isRtl') && 'end',
          }}>
          {request.userSay}
        </p>
      </div>
      <div className={classes.selectItem}>
        <p className={classes.desc}>Intent</p>
        <IntentsSelect
          id={request.triggeredIntentId}
          value={request.triggeredIntentName}
          onElementSelected={value => handleChangeIntent(value, request)}
        />
      </div>
      <div className={classes.selectItem}>
        <div className={classes.trainButton}>
          {request.trained ? (
            <div className={classes.trainedIcon}>
              <DoneIcon />
            </div>
          ) : (
            <TrainButton onClick={() => trainRequest(request)} />
          )}
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(TrainingRequestView)
