import React from 'react'
import NewBroadcastsList from './components/NewBroadcastsList'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { loadTags } from '../flowBuilder/api/tag'
import { getFlows } from '../flowBuilder/api/flow'
import Modal from '../../uiKit/Modal'
import BroadcastModal from './components/BroadcastModal'

const styles = () => ({
  tab: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
  broadcastsList: {
    flexBasis: '100%',
    minWidth: 400,
    paddingRight: '30px',
  },
  paperModal: {
    width: '460px',
    padding: '30px 50px 24px',
    outline: 'none',
    position: 'absolute',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    backgroundColor: '#fff',
  },
})

class Broadcasts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      timezone: null,
      activeBot: {},
      selectedDate: null,
      shownDate: null,
      openedBroadcastModal: false,
    }

    this.state.selectedDate = !this.state.activeBot.length
      ? moment()
      : moment.utc().utcOffset(this.state.activeBot.timezone)
    this.state.shownDate = !this.state.activeBot.length
      ? moment()
      : moment.utc().utcOffset(this.state.activeBot.timezone)
  }

  componentDidMount() {
    loadTags(this.props.match.params.botId)
    getFlows(this.props.match.params.botId)
  }

  closeBroadcastModal = () => {
    this.setState({ openedBroadcastModal: false })
  }
  openBroadcastModal = () => {
    this.setState({ openedBroadcastModal: true })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.tab}>
        <div className={classes.broadcastsList}>
          <NewBroadcastsList
            activeBot={this.props.activeBot}
            handleBroadcastModalOpen={this.openBroadcastModal}
          />
        </div>
        <Modal
          classes={{ paper: classes.paperModal }}
          open={this.state.openedBroadcastModal}
          onClose={this.closeBroadcastModal}>
          <BroadcastModal onClose={this.closeBroadcastModal} />
        </Modal>
      </div>
    )
  }
}

Broadcasts.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  tags: state.tags,
  broadcasts: state.broadcasts,
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Broadcasts)),
)
