import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles, customStyles } from './styles'
import Select from 'react-select'

const options = [
  { value: 'AND', label: 'and' },
  { value: 'OR', label: 'or' },
]

const ConjunctionSelect = props => {
  const { classes, value, touched, onFocus, onChange } = props

  const getValue = value => {
    return options.find(option => option.value === value)
  }

  return (
    <div className={classes.container}>
      <Select
        styles={customStyles}
        options={options}
        value={getValue(value)}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement={'auto'}
        touched={touched}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  )
}
ConjunctionSelect.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ConjunctionSelect)
