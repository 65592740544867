import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import uuidv1 from 'uuid/v1'

import { availableDefaultMessages } from '../messages/AvailableMessages'
import { OtherIcon } from '../../../../uiKit/icons/Icons'
import AddActionModal from '../AddActionModal/AddActionModal'

import { styles } from './styles'

const NewMessage = props => {
  const { classes, atom, updateAtom } = props
  const [open, setOpen] = useState(false)

  const handleCreateNewMessage = newMessage => {
    const newAtom = { ...atom }

    if (!newAtom.messages) newAtom.messages = []
    newMessage['tempId'] = uuidv1()
    newAtom.messages.push({ ...newMessage })

    updateAtom(newAtom)
  }

  return (
    <>
      <div className={classes.container}>
        {availableDefaultMessages.map((message, index) => (
          <div
            key={index}
            className={classes.listItem}
            onClick={() => handleCreateNewMessage(message.newMessage)}>
            {message.icon}
            <div>{message.name}</div>
          </div>
        ))}

        <div className={classes.listItem} onClick={() => setOpen(true)}>
          <OtherIcon width="17" height="6" color="#B0B2C0" />
          <div>Other</div>
        </div>
      </div>

      <AddActionModal
        open={open}
        atom={atom}
        updateAtom={updateAtom}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
NewMessage.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(NewMessage)
