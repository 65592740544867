export const customStyles = {
  control: (provided, state) => {
    const error = state.selectProps.error

    return {
      ...provided,
      border: error ? '1px solid red' : '1px solid #D7D8DF',
      outline: 'none',
      boxShadow: 'none',
      borderRadius: 10,
      padding: '2px 6px',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid rgba(53, 64, 82, 0.5)',
        boxShadow: 'none',
      },
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#354052',
    fontSize: 14,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#dfecff' : '#ffffff',
      color: '#354052',
      '&:hover': {
        fontSize: 14,
        backgroundColor: state.isFocused ? '#dfecff' : '#ffffff',
        color: '#354052',
      },
    }
  },
  menu: provided => ({
    ...provided,
    padding: 0,
    overflow: 'hidden',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(53, 64, 82, 0.5)',
    fontSize: 14,
    width: 'max-content',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: 4,
    backgroundColor: '#5A98F7',
    '& > div': {
      color: '#ffffff',
    },
    '& > div: hover': {
      backgroundColor: '#5A98F7',
      color: '#ffffff',
    }
  }),
}
