/**
 * Created by Admin on 13.02.2018.
 */
import React from 'react'
import { IconButton } from '@material-ui/core'
import MessageButton from './MessageButton'
import MessageQuickReplies from './MessageQuickReplies'
import ImageDropzone from '../../../../uiKit/inputs/FileDropzone'
import { connect } from 'react-redux'
import InputWithParams from '../../../../uiKit/InputWithParams/InputWithParams'

const styles = {
  container: {
    width: 280,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    position: 'relative',
  },
  message: {
    position: 'relative',
  },
  button: {
    // marginLeft: "30px",
  },
  addElement: {
    borderTop: '1px solid rgb(229, 229, 229)',
    cursor: 'pointer',
    color: 'grey',
  },
}

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  addElement() {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.elements.push({
      title: '',
      subtitle: '',
      image_url: '',
      buttons: [],
    })
    this.props.updateMessage(newMessage)
  }

  handleChange(elemIndex, value, name) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.elements[elemIndex][name] = value
    this.props.updateMessage(newMessage)
  }

  addNewButtonToElem(elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (newMessage.attachment.payload.elements[elemIndex].buttons == null) {
      newMessage.attachment.payload.elements[elemIndex].buttons = []
    }
    newMessage.attachment.payload.elements[elemIndex].buttons.push({
      type: 'postback',
      title: '',
      payload: '',
    })
    this.props.updateMessage(newMessage)
  }

  addNewButton() {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (newMessage.attachment.payload.buttons == null) {
      newMessage.attachment.payload.buttons = []
    }
    newMessage.attachment.payload.buttons.push({
      type: 'postback',
      title: '',
      payload: '',
    })
    this.props.updateMessage(newMessage)
  }

  updateBtn(btn, btnIndex, elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (elemIndex !== null)
      newMessage.attachment.payload.elements[elemIndex].buttons[btnIndex] = btn
    else newMessage.attachment.payload.buttons[btnIndex] = btn
    this.props.updateMessage(newMessage)
  }

  deleteButton(btnIndex, elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (elemIndex !== null)
      newMessage.attachment.payload.elements[elemIndex].buttons.splice(
        btnIndex,
        1,
      )
    else newMessage.attachment.payload.buttons.splice(btnIndex, 1)
    this.props.updateMessage(newMessage)
  }

  isEnoughElements() {
    const numOfElem = 4
    return this.props.message.attachment.payload.elements.length < numOfElem
  }

  onDeleteElem(elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.elements.splice(elemIndex, 1)
    this.props.updateMessage(newMessage)
  }

  render() {
    const { buttons } = this.props.message.attachment.payload
    const elementsNum = this.props.message.attachment.payload.elements.length
    const { scrollBlock } = this.props

    return (
      <div>
        <div style={styles.container}>
          {this.props.message.attachment.payload.elements.map(
            (elem, elemIndex) => (
              <div key={elem.id} style={styles.message}>
                <ImageDropzone
                  value={elem.image_url}
                  onChange={image_url =>
                    this.handleChange(elemIndex, image_url, 'image_url')
                  }
                  title="Try choosing or dropping some image here."
                />

                <InputWithParams
                  onChange={value =>
                    this.handleChange(elemIndex, value, 'title')
                  }
                  value={elem.title}
                  placeholder="Title"
                  styles={{
                    height: 70,
                  }}
                  title="Title"
                  scrollBlock={scrollBlock}
                />

                <InputWithParams
                  onChange={value =>
                    this.handleChange(elemIndex, value, 'subtitle')
                  }
                  value={elem.subtitle}
                  placeholder="Subtitle"
                  styles={{
                    height: 70,
                  }}
                  title="Subtitle"
                  scrollBlock={scrollBlock}
                />

                <div className="rep-btns-container">
                  {elem.buttons != null &&
                    elem.buttons.map((btn, btnIndex) => (
                      <MessageButton
                        btn={btn}
                        key={btnIndex}
                        onUpdate={btn =>
                          this.updateBtn(btn, btnIndex, elemIndex)
                        }
                        onDelete={() => this.deleteButton(btnIndex, elemIndex)}
                      />
                    ))}

                  {(elem.buttons == null || elem.buttons.length < 1) && (
                    <div
                      className="add-button"
                      onClick={() => this.addNewButtonToElem(elemIndex)}
                      style={styles.button}>
                      add button
                    </div>
                  )}
                </div>
                {elementsNum > 2 && (
                  <IconButton
                    aria-label="Delete"
                    style={{
                      position: 'absolute',
                      right: '-25px',
                      top: '-5px',
                    }}
                    onClick={() => this.onDeleteElem(elemIndex)}>
                    <img src="/images/platform/delete.svg" alt="delete" />
                  </IconButton>
                )}
              </div>
            ),
          )}
          {this.isEnoughElements() && (
            <div
              onClick={() => this.addElement()}
              style={{ ...styles.addElement, display: 'flex' }}>
              <div>
                <p style={{ margin: 5, color: '#c1c1c1' }}>+ ADD ITEM</p>
              </div>
            </div>
          )}
          {
            <div
              className="rep-btns-container"
              style={{ borderTop: '1px solid #e5e5e5' }}>
              {buttons != null &&
                buttons.map((btn, btnIndex) => (
                  <MessageButton
                    btn={btn}
                    key={btnIndex}
                    onUpdate={btn => this.updateBtn(btn, btnIndex, null)}
                    onDelete={() => this.deleteButton(btnIndex, null)}
                  />
                ))}
              {(buttons == null || buttons.length < 1) && (
                <div className="add-button" onClick={() => this.addNewButton()}>
                  Add button
                </div>
              )}
            </div>
          }
          {this.props.onDelete && (
            <div className="dlt-msg" onClick={() => this.props.onDelete()}>
              <img src="/images/platform/delete.svg" alt="delete" />
            </div>
          )}
        </div>
        <MessageQuickReplies
          message={this.props.message}
          updateMessage={message => this.props.updateMessage(message)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  params: state.attributes,
})
export default connect(mapStateToProps)(Template)
