import React, {MouseEventHandler, useMemo} from 'react'

import classes from './styles.module.scss'
import RequestTypeSelect from '../RequestTypeSelect/RequestTypeSelect'
import InputWithParams from '../../../../uiKit/InputWithParams/InputWithParams'
import MessageTemplate from '../MessageTemplate/MessageTemplate'
import ApiCallTabs from '../ApiCallTabs/ApiCallTabs'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import {ApiCallMessageType, ApiCallMethodType, OptionItemType} from '../../../../models/MessageTypes'

interface Props {
  saveTime: Date
  scrollBlock: any
  message: ApiCallMessageType
  onDelete: MouseEventHandler<HTMLElement>
  updateMessage(message: ApiCallMessageType): void
}

const ApiCall: React.FC<Props> = ({ saveTime, scrollBlock, message, onDelete, updateMessage }) => {
  const messageCopy = useMemo(() => deepCopyFunction(message), [message])
  const disable: boolean = useMemo(() => {
    const disableMethodTypes: ApiCallMethodType[] = ['GET', 'DELETE']

    return disableMethodTypes.includes(message.apiCall.method)
  }, [message.apiCall.method])

  const handleUpdateRequestURL = (value: string) => {
    messageCopy.apiCall.url = value
    updateMessage(messageCopy)
  }

  const handleUpdateRequestType = (value: OptionItemType) => {
    messageCopy.apiCall.method = value.value
    updateMessage(messageCopy)
  }

  return (
    <MessageTemplate title={'API call'} tooltipText={''} onDelete={onDelete}>
      <div className={classes.body}>
        <div className={classes.inputsWrap}>
          <div>
            <p className={classes.label}>Request type</p>
            <RequestTypeSelect value={message.apiCall.method} touched={false} onChange={handleUpdateRequestType} />
          </div>

          <div className={classes.inputWrap}>
            <p className={classes.label}>Request URL</p>
            <InputWithParams
              placeholder="enter URL (eg. https://botscrew.com)"
              onChange={handleUpdateRequestURL}
              value={message.apiCall.url || ''}
              scrollBlock={scrollBlock}
              singleLine={true}
              saveTime={saveTime}
              required={true}
              styles={{
                height: 42,
              }}
            />
          </div>
        </div>

        <ApiCallTabs disable={disable} message={message} updateMessage={updateMessage} scrollBlock={scrollBlock} />
      </div>
    </MessageTemplate>
  )
}

export default ApiCall
