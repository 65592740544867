export const styles = () => ({
  container: {
    height: 'calc(100% - 80px)',
    margin: '40px 40px 40px 0px',
    background: '#FFFFFF',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
    minWidth: 940,
    display: 'flex',
    overflow: 'hidden',
  },
})
