/**
 * Created by Admin on 13.02.2018.
 */
import React from 'react'
import MessageQuickReplies from './MessageQuickReplies'
import { IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import LibraryAdd from '@material-ui/icons/LibraryAdd'
import FileDropzone from '../../../../uiKit/inputs/FileDropzone'

const styles = {
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 'auto',
  },
  view: {
    overflow: 'scroll',
    marginRight: '-17px',
    marginBottom: '-17px',
    paddingBottom: 12,
    display: 'flex',
  },
  message: {
    height: 'max-content',
    minWidth: '280px',
    margin: '5px',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    marginRight: 20,
    position: 'relative',
  },
  addElement: {
    minWidth: '280px',
    margin: '5px',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    marginRight: 20,
    position: 'relative',
  },
}

class RandomTextMessage extends React.Component {
  state = {}

  handleChangeUrl(fileUrl, elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.items[elemIndex].attachment.payload.url = fileUrl
    this.props.updateMessage(newMessage)
  }

  addItem() {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.items.push({
      attachment: {
        type: 'image',
        payload: {
          url: '',
        },
      },
    })
    this.props.updateMessage(newMessage)
  }

  onDeleteItem(elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.items.splice(elemIndex, 1)
    this.props.updateMessage(newMessage)
  }

  render() {
    const elementsNum = this.props.message.items.length
    const { rtl } = this.props

    return (
      <div>
        <Scrollbars
          style={styles.container}
          renderView={props => <div {...props} style={styles.view} />}>
          {this.props.message.items.map((elem, elemIndex) => (
            <div key={elemIndex} style={styles.message}>
              <FileDropzone
                value={elem.attachment.payload.url}
                onChange={fileUrl => this.handleChangeUrl(fileUrl, elemIndex)}
                title="Replace image"
              />
              {elementsNum > 1 && (
                <IconButton
                  aria-label="Delete"
                  style={{
                    position: 'absolute',
                    right: '-25px',
                    top: '-5px',
                  }}
                  onClick={() => this.onDeleteItem(elemIndex)}>
                  <img src="/images/platform/delete.svg" alt="delete" />
                </IconButton>
              )}
            </div>
          ))}

          <div
            onClick={() => this.addItem()}
            style={{ ...styles.addElement, display: 'flex' }}>
            <div style={{ margin: 'auto', color: '#c1c1c1' }}>
              <LibraryAdd />
            </div>
          </div>

          {this.props.onDelete && (
            <IconButton
              aria-label="Delete"
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              onClick={() => this.props.onDelete()}>
              <img src="/images/platform/delete.svg" alt="delete" />
            </IconButton>
          )}
        </Scrollbars>
        <MessageQuickReplies
          message={this.props.message}
          rtl={rtl}
          updateMessage={message => this.props.updateMessage(message)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  params: state.attributes,
})

export default connect(mapStateToProps)(RandomTextMessage)
