import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Paragraph from '../../../../../uiKit/texts/Paragraph'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import { deleteBot } from '../../../../home/api/bots'
import Modal from '../../../../../uiKit/Modal'

import { styles } from './styles'

const DeleteBotModal = ({ classes, open, onClose, activeBot }) => {
  return (
    <Modal open={open} onClose={onClose} title="Delete the chatbot">
      <Paragraph>
        Are you sure you want to delete a chatbot
        <span style={{ color: '#1658F3' }}>{' ' + activeBot.name}</span>? You
        won't be able to undo this action.
      </Paragraph>
      <div className={classes.buttonsHolder}>
        <SubmitButton onClick={() => deleteBot(activeBot.id)} title="Delete" />
        <SubmitButton onClick={onClose} empty title="Cancel" />
      </div>
    </Modal>
  )
}

DeleteBotModal.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(DeleteBotModal))
