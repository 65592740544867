import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import DeleteBotModal from '../DeleteBotModal/DeleteBotModal'
import Heading from '../../../../../uiKit/texts/Heading'
import Paragraph from '../../../../../uiKit/texts/Paragraph'

import { styles } from './styles'

const DeleteBotBlock = ({ classes }) => {
  const [openDeleteBotModal, setOpenDeleteBotModal] = useState(false)

  const handleOpenDeleteBotModal = openDeleteBotModal => {
    setOpenDeleteBotModal(openDeleteBotModal)
  }

  return (
    <>
      <div className={classes.container}>
        <Heading>Delete Bot</Heading>
        <Paragraph>
          Deleting a bot will destroy the agent with all corresponding data and
          cannot be undone.
        </Paragraph>
        <div
          className={classes.btnDelete}
          onClick={() => handleOpenDeleteBotModal(true)}>
          Delete
        </div>
      </div>

      <DeleteBotModal
        open={openDeleteBotModal}
        onClose={() => handleOpenDeleteBotModal(false)}
      />
    </>
  )
}

DeleteBotBlock.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(DeleteBotBlock)
