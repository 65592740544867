/* eslint-disable */
import React from 'react'

export const RandomRedirectIcon = () => {

  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.7797 11.1592L8.3597 12.5792C7.6797 11.8892 7.0197 10.9992 6.5697 9.63922L8.5097 9.14922C8.8297 10.0392 9.2797 10.6492 9.7797 11.1592ZM10.1497 5.14922L7.3497 2.34922C7.1497 2.14922 6.8397 2.14922 6.6397 2.34922L3.8497 5.14922C3.5397 5.45922 3.7597 5.99922 4.2097 5.99922H6.0197C6.0397 6.80922 6.0997 7.53922 6.2097 8.16922L8.1497 7.67922C8.0797 7.19922 8.0297 6.62922 8.0197 5.99922H9.7997C10.2397 5.99922 10.4597 5.45922 10.1497 5.14922ZM20.1497 5.14922L17.3597 2.35922C17.1597 2.15922 16.8497 2.15922 16.6497 2.35922L13.8597 5.14922C13.5397 5.45922 13.7597 5.99922 14.2097 5.99922H15.9897C15.8897 9.67922 14.7097 10.7492 13.4497 11.8792C12.9497 12.3192 12.4397 12.7992 11.9997 13.4292C11.6597 12.9392 11.2697 12.5492 10.8697 12.1892L9.4597 13.5992C10.3897 14.4492 10.9997 15.1392 10.9997 16.9992V20.9992C10.9997 21.5492 11.4497 21.9992 11.9997 21.9992C12.5497 21.9992 12.9997 21.5492 12.9997 20.9992V16.9992C12.9997 14.9792 13.7097 14.3392 14.7897 13.3692C16.1697 12.1292 17.8697 10.5892 17.9897 5.99922H19.7897C20.2397 5.99922 20.4597 5.45922 20.1497 5.14922Z"
        fill="#5A98F7"
      />
    </svg>
  )
}
