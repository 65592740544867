import { IS_AUTHENTICATED, IS_NOT_AUTHENTICATED } from '../actions/security'
import { access } from '../../../security'

const security = (state = { token: null, authenticated: false }, action) => {
  switch (action.type) {
  case IS_AUTHENTICATED:
    access.loadRole()
    return action.security
  case IS_NOT_AUTHENTICATED:
    return action.security
  default:
    return state
  }
}

export default security
