/**
 * Created by dshevchenko on 15.04.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Checkbox,
  FormControlLabel,
  Popover,
  withStyles,
} from '@material-ui/core'
import InputHolder from '../../../../uiKit/inputs/InputHolder'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'

const styles = theme => ({
  levelItem: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #EBEBEF',
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#3A3F62',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: '#B0B2C0',
    marginBottom: 0,
    marginTop: 5,
  },
  submenu: {
    fontSize: 12,
    fontFamily: 'Lato',
    color: '#3A3F62',
    marginBottom: 0,
    marginTop: 2,
  },
  tab: {
    flexBasis: '33%',
    fontSize: 14,
    fontFamily: 'Lato',
    color: '#616581',
    textAlign: 'center',
    padding: '12px 0',
    cursor: 'pointer',
  },
  activeTab: {
    flexBasis: '33%',
    fontSize: 14,
    fontFamily: 'Lato',
    color: '#1658F3',
    textAlign: 'center',
    padding: '12px 0',
    cursor: 'pointer',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-around',
    borderBottom: '1px solid #EBEBEF',
  },
  popover: {
    width: 300,
  },
  tabContainer: {
    padding: '16px',
    borderBottom: '1px dashed #EBEBEF',
  },
  paragraph: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#616581',
    fontWeight: 300,
  },
})

class CustomPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      menuItem: null,
    }
  }

  functionValidURL = str => {
    try {
      new URL(str)
      return true
    } catch (_) {
      return false
    }
  }

  toggle = value => {
    this.setState({
      open: value,
    })
  }

  submitItem = () => {
    const menuItem = this.state.menuItem
    menuItem.titles = this.props.menuItem.titles
    if (menuItem.type === 'web_url' && !this.functionValidURL(menuItem.url)) {
      this.setState({
        invalidUrl: true,
      })
    } else {
      if (this.state.invalidUrl) {
        this.setState({
          invalidUrl: false,
        })
      }
      switch (menuItem.type) {
      case 'web_url': {
        delete menuItem.payload
        delete menuItem.callToActions
        break
      }
      case 'postback': {
        delete menuItem.url
        delete menuItem.callToActions
        break
      }
      }
      this.props.onClose()
      this.props.onUpdate(menuItem)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.menuItem === null && props.menuItem !== null) {
      return {
        menuItem: props.menuItem,
      }
    }
  }

  handleChange = (name, value) => {
    const item = JSON.parse(JSON.stringify(this.state.menuItem))
    item[name] = value
    this.setState({
      menuItem: item,
    })
  }

  render() {
    const { classes, open, rtl } = this.props
    const { menuItem, invalidUrl } = this.state
    return (
      <Popover
        open={open}
        onClose={() => {
          this.props.onClose()
        }}
        anchorEl={this.props.anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.222685)',
            borderRadius: 10,
            overflow: 'visible',
          },
        }}>
        <div className={classes.popover}>
          <div style={{ padding: '24px 16px 16px' }}>
            <InputHolder
              holderStyle={{ width: 268 }}
              inputStyle={{ color: '#616581',
                unicodeBidi: rtl && 'bidi-override',
                direction: rtl && 'rtl'
              }}
              defaultValue={this.props.menuItem.title}
              placeholder="Enter menu item title"
              onChange={event => {
                this.handleChange('title', event.target.value)
              }}
              handleKeyPress={e => {
                if (e.charCode === 13) {
                  this.submitItem()
                }
              }}
              maxLength={30}
            />
          </div>
          <div>
            <div>
              <div className={classes.tabs}>
                <div
                  className={
                    menuItem.type === 'postback'
                      ? classes.activeTab
                      : classes.tab
                  }
                  onClick={() => this.handleChange('type', 'postback')}>
                  Postback
                </div>
                <div
                  className={
                    menuItem.type === 'web_url'
                      ? classes.activeTab
                      : classes.tab
                  }
                  onClick={() => this.handleChange('type', 'web_url')}>
                  URL
                </div>
              </div>
              {menuItem.type === 'postback' && (
                <div className={classes.tabContainer}>
                  <AtomSelect value={menuItem.payload}
                    onChange={({ label }) => this.handleChange('payload', label)}/>
                </div>
              )}
              {menuItem.type === 'web_url' && (
                <div className={classes.tabContainer}>
                  <InputHolder
                    holderStyle={
                      !invalidUrl
                        ? { width: 268 }
                        : {
                          width: 268,
                          border: '1px solid #FF624C',
                        }
                    }
                    inputStyle={
                      !invalidUrl ? { color: '#616581' } : { color: '#FF624C' }
                    }
                    defaultValue={menuItem.url}
                    placeholder="Enter URL"
                    onChange={event =>
                      this.handleChange('url', event.target.value)
                    }
                    handleKeyPress={event =>
                      this.handleChange('url', event.target.value)
                    }
                    maxLength={90}
                  />
                  {invalidUrl && (
                    <p style={{ color: '#FF624C', fontSize: 14 }}>
                      URL is invalid
                    </p>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={menuItem.enableMessengerExtensions}
                        onChange={value => {
                          this.handleChange(
                            'enableMessengerExtensions',
                            !menuItem.enableMessengerExtensions,
                          )
                        }}
                        value="messengerExtensions"
                        color="primary"
                      />
                    }
                    label="Open in Messenger"
                  />
                </div>
              )}
              <div style={{ padding: 16 }}>
                <SubmitButton empty onClick={this.submitItem} title="Save" />
              </div>
            </div>
          </div>
        </div>
      </Popover>
    )
  }
}

CustomPopover.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(CustomPopover),
)
