import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import Pagination from '../../../../uiKit/table/Pagination'
import { styles } from './Training-styles'
import TrainingRequest from './TrainingRequest-view'
import { PAGE_SIZE } from './Training-config'

function TrainingRequestsView(props) {
  const {
    nlpRequests,
    classes,
    page,
    handleChangeIntent,
    trainRequest,
    handleChangePage,
  } = props
  return (
    <div className={classes.Wrap}>
      {nlpRequests.data.length &&
        nlpRequests.data.map(request => (
          <TrainingRequest
            key={request.id}
            request={request}
            handleChangeIntent={handleChangeIntent}
            trainRequest={trainRequest}
          />
        ))}
      <Pagination
        page={page}
        rowsPerPage={PAGE_SIZE}
        count={nlpRequests.totalCount}
        onChangePage={handleChangePage}
      />
    </div>
  )
}

export default withStyles(styles)(TrainingRequestsView)
