import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Analytics from './components/Analytics'
import Funnel from './components/Funnel'
import { HOME_PATH } from '../../configs'

const AnalyticsContainer = () => {
  const pathRoute = `${HOME_PATH}/bot/:botId/analytics`

  return (
    <Switch>
      <Route exact path={pathRoute}>
        <Analytics />
      </Route>
      <Route exact path={`${pathRoute}/:funnelId`}>
        <Funnel />
      </Route>
    </Switch>
  )
}

export default AnalyticsContainer
