import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { WHITE, COMET_GREY, CORNFLOWER_BLUE, ATHENS_GRAY_DARK } from 'constants/colors'

const AtomItem = styled(NavLink)`
  margin: 5px;
  border: 1px solid ${ATHENS_GRAY_DARK};
  background-color: ${WHITE};
  color: ${COMET_GREY};
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  
  &:hover {
    color: ${COMET_GREY};
  }

  &.active {
    color: white;
    background-color: ${CORNFLOWER_BLUE};
    border: 1px solid ${CORNFLOWER_BLUE};
  }

  &.active path {
    stroke: ${WHITE};
  }
`

export { AtomItem }
