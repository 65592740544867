import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'

import SubmitButton from 'uiKit/buttons/SubmitButton'
import { AlertCircleIcon } from 'uiKit/icons/AlertCircleIcon'
import { AtomDeleteDataType } from 'models/AtomDeleteDataType'
import * as S from './DeleteAtomAlertModal.style'

interface Props {
  atom: any
  handleClose: () => void
  details: AtomDeleteDataType
}

export const DeleteAtomAlertModal: React.FC<Props> = ({ details, atom, handleClose }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Modal open={true} onClose={handleClose}>
      <S.Container>
        <AlertCircleIcon />
        <S.Title>Atom "{atom.name}" cannot be deleted because it is used in chatbot logic</S.Title>
        {showDetails && (
          <S.List>
            <S.Label>Connected atoms ({details?.atoms?.length || 0}):</S.Label>
            {details?.atoms?.map(atom => (
              <S.Item key={atom}>{atom}</S.Item>
            ))}
            <S.Label>Connected FAQs ({details?.faqs?.length || 0}):</S.Label>
            {details?.faqs?.map(atom => (
              <S.Item key={atom}>{atom}</S.Item>
            ))}
            <S.Label>Connected triggers ({details?.triggers?.length || 0}):</S.Label>
            {details?.triggers?.map(atom => (
              <S.Item key={atom}>{atom}</S.Item>
            ))}
            <S.Label>Connected Main Menu items ({details?.menus?.length || 0}):</S.Label>
            {details?.menus?.map(atom => (
              <S.Item key={atom}>{atom}</S.Item>
            ))}
            <S.Label>Connected Greetings ({details?.greetings?.length || 0}):</S.Label>
            {details?.greetings?.map(atom => (
              <S.Item key={atom}>{atom}</S.Item>
            ))}
            <S.Label>Connected Broadcasts ({details?.broadcasts?.length || 0}):</S.Label>
            {details?.broadcasts?.map(atom => (
              <S.Item key={atom}>{atom}</S.Item>
            ))}
          </S.List>
        )}
        <S.ButtonsWrap>
          {!showDetails && <SubmitButton empty title="Details" onClick={() => setShowDetails(true)} />}
          <SubmitButton onClick={handleClose} title="Ok" />
        </S.ButtonsWrap>
      </S.Container>
    </Modal>
  )
}
