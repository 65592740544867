export const styles = () => ({
  container: {
    width: 254,
    borderRadius: 10,
    border: '1px solid #EBEBEF',
    color: '#3A3F62',
    position: 'relative',
  },
  header: {
    padding: 16,
    borderBottom: '1px solid #EBEBEF',
    fontWeight: 700,
  },
  body: {
    padding: '0 16px 16px',
  },
  switcherWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addButton: {
    color: '#1658F3',
    cursor: 'pointer',
    marginTop: 8,
  },
  atomWrap: {
    margin: '8px 0',
    position: 'relative',
    '&:hover': {
      '& i': {
        display: 'block',
      },
    },
  },
  deleteRandomAtomWrap: {
    position: 'absolute',
    cursor: 'pointer',
    top: 3,
    right: -16,
    zIndex: 5,
    display: 'none',
    height: '29px',
    overflow: 'hidden',
  },
  '@global': {
    '.MuiSwitch-root-215': {
      left: 16,
    },
  },
})

export const SwitchStyles = {
  checked: {
    color: '#1658F3',
  },
}
