import { store } from '../../../index'

export const SAVE_SUPPORT_USERS = 'SAVE_SUPPORT_USERS'
export const SAVE_MORE_SUPPORT_USERS = 'SAVE_MORE_SUPPORT_USERS'
export const SAVE_CLOSED_SUPPORT_USERS = 'SAVE_CLOSED_SUPPORT_USERS'
export const SAVE_MORE_CLOSED_SUPPORT_USERS = 'SAVE_MORE_CLOSED_SUPPORT_USERS'
export const UPDATE_SUPPORT_USER_LIST = 'UPDATE_SUPPORT_USER_LIST'
export const UPDATE_USER_UNREAD_MESSAGE = 'UPDATE_USER_UNREAD_MESSAGE'

export const saveSupportUsers = (supportUsers = [], adminUserId) => {
  store.dispatch({
    type: SAVE_SUPPORT_USERS,
    payload: {
      supportUsers,
      adminUserId,
    },
  })
}

export const saveMoreSupportUsers = (supportUsers = [], adminUserId) => {
  store.dispatch({
    type: SAVE_MORE_SUPPORT_USERS,
    payload: {
      supportUsers,
      adminUserId,
    },
  })
}

export const saveClosedSupportUsers = (supportUsers = []) => {
  store.dispatch({
    type: SAVE_CLOSED_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveMoreClosedSupportUsers = supportUsers => {
  store.dispatch({
    type: SAVE_MORE_CLOSED_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const updateSupportUserList = (user, adminUserId) => {
  store.dispatch({
    type: UPDATE_SUPPORT_USER_LIST,
    payload: {
      user,
      adminUserId,
    },
  })
}

export const updateUserUnreadMessages = user => {
  store.dispatch({
    type: UPDATE_USER_UNREAD_MESSAGE,
    payload: {
      user,
    },
  })
}
