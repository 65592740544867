import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Heading from '../../../uiKit/texts/Heading'

const styles = theme => ({
  image: {
    width: 64,
    height: 64,
    opacity: '.2',
    marginBottom: 16,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '32px',
    textAlign: 'center',
  },
  text: {
    margin: '0 0 24px',
    color: theme.palette.primary.text,
    fontSize: 14,
  },
  buttonHolder: {
    textAlign: 'center',
  },
})

class EmptyNlp extends React.Component {
  state = {
    open: false,
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <img className={classes.image} src="/images/platform/gray-chain.svg" />

        <Heading>Connect NLP agent</Heading>

        <p className={classes.text}>
          Connect NLP agent to be able to make your bot smarter.
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EmptyNlp)),
)
