export const circleChartContainerTitle = 'Conversations covered'
export const hourlyChartContainerTitle = 'Average conversations by time of day'

export const areaChartTooltipText = `This chart shows the number of daily
        active users for the selected period. For a given day,
         Daily Users is the number of unique users who were
         chatting with the bot that day.`
export const conversationsCoveredTooltipText =
  // eslint-disable-next-line max-len
  'This chart shows the ratio of conversations covered only by the bot and conversations in which a user asked for human support.'

export const hourlyChartTooltipText = `This chart shows the average amount of users per hour
        who were interacting with the bot for the selected period.`

// eslint-disable-next-line max-len
export const channelsChartTooltipText = 'The percentage of users active on each channel over the selected period.'

export const noDataText =
  'You have no conversations yet. They will appear here after your chatbot will get first users'
