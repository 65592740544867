import styled from 'styled-components'
import { FIORD_BLUE, OXFORD_BLUE, WHITE } from 'constants/colors'

const Title = styled.p`
  font-size: 16px;
  color: ${OXFORD_BLUE};
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
`

const ButtonsWrap = styled.div`
  display: flex;
  gap: 8px;
`

const Container = styled.div`
  position: absolute;
  background-color: ${WHITE};
  padding: 40px 90px;
  outline: none;
  width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Label = styled.p`
  color: ${FIORD_BLUE};
  font-size: 12px;
  font-weight: 700;
  margin: 14px 0 4px;
`

const Item = styled.p`
  color: ${FIORD_BLUE};
  font-size: 12px;
  margin: 4px 0;
`

const List = styled.div`
  margin-bottom: 24px;
  text-align: center;
  max-height: calc(100vh - 400px);
  overflow: scroll;
  width: 100%;
`

export { Title, ButtonsWrap, Container, Label, Item, List }
