export const FormatOption = (options, type, allowedAttributes) => {
  return options
    .filter(option => option.type === type)
    .filter(option => {
      if (allowedAttributes?.length) {
        return allowedAttributes.includes(option.name)
      }
      return true
    })
    .map(option => {
      return {
        label: option.name,
        value: option.id,
        type: option.type,
        description: option.description,
      }
    })
}
