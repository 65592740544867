export const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'visible',
    background: 'red',
  },
  table: {
    minWidth: 700,
    overflow: 'visible',
  },
  menuItem: {
    background: 'red !',
  },
  createTriggerButton: {
    float: 'right',
  },
  button: {
    border: '1px solid #1658F3',
    color: theme.palette.primary.main,
    background: '#FFFFFF',
    borderRadius: 10,
    padding: '10px 34px',
    boxShadow: 'none',
    '&:hover': {
      background: '#ffffff',
    },
  },
  mainSelected: {
    display: 'flex',
    margin: '20px 0',
    width: '100%',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
    position: 'relative',
  },
  selectItem: {
    flexBasis: '33%',
    padding: 24,
    borderRight: '1px solid #EBEBEF',
  },
  desc: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.text.header,
  },
  selectIcon: {
    background: 'red',
  },
  trainedIcon: {
    background: '#11C314',
    border: '1px solid #11C314',
    borderRadius: 10,
    padding: '10px 34px',
    boxShadow: 'none',
    lineHeight: '1.4em',
    fontSize: '0.875rem',
    minHeight: '36px',
  },
  tabsTrain: {
    display: 'flex',
    cursor: 'pointer',
    maxHeight: 45,
    alignItems: 'center',
  },
  leftTab: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    background: theme.palette.primary.light,
    color: '#ffffff',
    padding: '13px 24px',
    maxHeight: '100%',
  },
  rightTab: {
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
    background: '#ffffff',
    color: theme.palette.primary.text,
    padding: '13px 24px',
    maxHeight: '100%',
  },
  trainButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 24,
    color: theme.palette.text.header,
    flexBasis: '15%',
    marginRight: '32px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  activeNlp: {
    background: '#5A98F7 !important',
    color: '#ffffff !important',
  },
  defaultNlp: {
    background: '#ffffff !important',
    color: '#616581 !important',
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'row',
  },
  filterText: {
    marginRight: '17px',
    fontSize: '14px',
    fontFamily: 'Lato',
    color: theme.palette.text.header,
  },
  selectWrapper: {
    minWidth: '145px',
  },
  userSay: {
    margin: 0,
  },
})
