/* eslint-disable */
import React from 'react'

export const ArrowLeft = () => {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 7H1" stroke="#1658F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 13L1 7L7 1" stroke="#1658F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
