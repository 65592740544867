import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select'

import { styles, customStyles } from './styles'
import { connect } from 'react-redux'
import { getAllUsers } from '../../tabs/support/api/users'

const UsersSelect = props => {
  const { value, onChange, activeBot } = props
  const [options, setOptions] = useState([])

  useEffect(() => {
    getAllUsers(activeBot.id).then(response => {
      const formattedUsers = response.users.map(user => ({
        label: user.id,
        value: user.id,
      }))
      setOptions(formattedUsers)
    })
  }, [])

  const getValue = value => {
    return options.find(option => option.value === value)
  }

  return (
    <AsyncSelect
      cacheOptions
      styles={customStyles}
      options={options}
      value={getValue(value)}
      maxMenuHeight={200}
      minMenuHeight={200}
      menuPlacement={'auto'}
      onChange={onChange}
      placeholder={'Select User Id'}
    />
  )
}

UsersSelect.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(UsersSelect))
