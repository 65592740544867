export const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
}

export const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
}

export const SORT_DATE = {
  label: 'Date',
  value: 'date',
}

export const SORT_SCORE = {
  label: 'Rate',
  value: 'score',
}

export const DIRECTION_ASC = {
  label: 'Ascending',
  value: 'asc',
}

export const DIRECTION_DESK = {
  label: 'Descending',
  value: 'desc',
}
