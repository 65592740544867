import { createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL } from '../../../configs'

async function updateTelegramCredentials(botId, data) {
  const response = await fetch(`${BASE_URL}/telegram/${botId}/subscribePage`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(data),
  })

  return await logoutOnRequestOrResponseJson(response)
}
export { updateTelegramCredentials }
