import React from 'react'
import { withStyles } from '@material-ui/core'
import Headline from '../../../uiKit/texts/Headline'
import { HomeHeader } from '../../account/components/HomeHeader'

const styles = theme => ({
  flex: {
    marginLeft: '30px',
    flex: 1,
  },
  header: {
    height: 80,
    width: '100%',
    background: theme.palette.primary.contrastText,
    zIndex: 2,
    position: 'fixed',
    top: 0,
    left: 0,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    fontFamily: "'Lato', sans-serif",
  },
  headerInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    justifyContent: 'flex-end',
  },
  title: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.headerText,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  container: {
    backgroundColor: theme.palette.primary.background,
    display: 'flex',
    flexDirection: 'column',
    padding: '80px 40px 40px',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
  },
  '@media (max-width: 991px)': {
    container: {
      padding: '90px 20px 20px !important',
    },
  },
  '@global': {
    '::-webkit-scrollbar': {
      width: 12,
    },

    '::-webkit-scrollbar-track': {
      borderRadius: 3,
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      webkitBoxShadow: 'inset 0 0 6px rgba(24, 24, 24, 0.5)',
      backgroundColor: 'rgba(99, 99, 99, 0.2)',
    },
  },
})

class Bots extends React.Component {
  state = {}

  render() {
    const { classes } = this.props

    return (
      <div>
        <HomeHeader title="Admin Panel" />
        <div className={classes.container}>
          <Headline title="Your bots" />
          <div className={classes.content}>{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Bots)
