import Modal from '@material-ui/core/Modal'
import { connect } from 'react-redux'
import React, { ReactEventHandler, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import { CrossIcon } from '../../../../uiKit/icons/CrossIcon'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { updateWidget } from '../../api/widget'
import { SPUN_PEARL_GREY } from '../../../../constants/colors'

import classes from './styles.module.scss'
import {OptionItemType} from '../../../../models/MessageTypes'
import {GreetingType, WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'

interface Props {
  match: any
  open: boolean
  selectedLanguage: OptionItemType
  widgetSettings: WidgetSettingsType
  selectedGreeting: GreetingType
  onClose: ReactEventHandler
}

const DeleteGreetingModal: React.FC<Props> = ({
  match,
  open,
  selectedGreeting,
  widgetSettings,
  selectedLanguage,
  onClose,
}) => {
  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])

  const handleDelete = (event: any) => {
    widgetCopy.languageConfigs[selectedLanguage.value].greetings.splice(selectedGreeting.index, 1)
    updateWidget(match.params.botId, widgetCopy)
    onClose(event)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <p className={classes.title}>Are you sure you want to delete {selectedGreeting.name} greeting?</p>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={SPUN_PEARL_GREY} />
        </div>
        <div className={classes.buttonsWrap}>
          <div className={classes.deleteButton} onClick={handleDelete}>
            Delete
          </div>
          <SubmitButton onClick={onClose} title="Cancel" />
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(DeleteGreetingModal))
