import {
  SAVE_INTENTS_SIZE,
  ADD_INTENT_SIZE,
  UPDATE_INTENTS_SIZE,
} from '../actions/intentsSize'

const intentsSize = (state = [], action) => {
  let intentsSize
  switch (action.type) {
  case SAVE_INTENTS_SIZE:
    return action.intentsSize
  case ADD_INTENT_SIZE:
    intentsSize = action.intentsSize + 1
    return intentsSize
  case UPDATE_INTENTS_SIZE:
    intentsSize = action.intentsSize - 1
    return intentsSize
  default:
    return state
  }
}

export default intentsSize
