import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import NameBlock from '../NameBlock/NameBlock'
import AttributeBlock from '../AttributeBlock/AttributeBlock'
import VersionBlock from '../VersionBlock/VersionBlock'
import DeleteBotBlock from '../DeleteBotBlock/DeleteBotBlock'

import { styles } from './styles'

const General = ({ classes }) => {
  return (
    <div className={classes.container}>
      <NameBlock />
      <AttributeBlock />
      <VersionBlock />
      <DeleteBotBlock />
    </div>
  )
}

General.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(General)
