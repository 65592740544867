import FieldInput from '../../../../uiKit/FieldInput/FieldInput'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'

import classes from './styles.module.scss'
import React, {MouseEventHandler, useMemo} from 'react'

interface Props {
  header: any
  onChange(header: any): any
  onDelete: MouseEventHandler<HTMLSpanElement>
}

const HeaderField: React.FC<Props> = ({ header, onChange, onDelete }) => {
  const headerCopy = useMemo(() => ({ ...header }), [header])

  const handleUpdateKey = value => {
    headerCopy.key = value
    onChange(headerCopy)
  }

  const handleUpdateValue = value => {
    headerCopy.value = value
    onChange(headerCopy)
  }

  return (
    <div className={classes.container}>
      <FieldInput
        placeholder={'Key'}
        onChange={handleUpdateKey}
        value={header.key}
        required={!!header.value}
      />

      <FieldInput
        placeholder={'Value'}
        onChange={handleUpdateValue}
        value={header.value}
        required={!!header.key}
      />

      <span className={classes?.iconWrap} onClick={onDelete}>
        <TrashIcon />
      </span>
    </div>
  )
}

export default HeaderField
