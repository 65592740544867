import React, { FC } from 'react'

import CheckIcon from '../../../../uiKit/icons/CheckIcon'

import classes from './styles.module.scss'

interface Props {
  label: string
  showCheck: boolean
  onClick: any
}

const PopoverItem: FC<Props> = ({ label, showCheck, onClick }) => (
  <div className={classes.filterItem} onClick={onClick}>
    <span>{showCheck && <CheckIcon />}</span> {label}
  </div>
)

export default PopoverItem
